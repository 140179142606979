import React, { createContext, useContext, useState } from 'react';

type ModalContextValue = {
  modal: ModalStateProps;
  showModal: <T>(component: React.FC<T>, props: Omit<T, 'onRequestClose'>) => void;
  hideModal: () => void;
}

type ModalStateProps = {
  component: React.FC<any> | null;
  props: any;
}

const ModalContext = createContext({} as ModalContextValue)

export const ModalContextProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<ModalStateProps>({
    component: null,
    props: {}
  });

  // Generic here make sure we pass right props to show modal even though the actual component state in this context has type 'any'
  function showModal<T>(component: React.FC<T>, props: Omit<T, 'onRequestClose'>) {
    setModal({ component, props });
  }

  const hideModal = () => {
    setModal({ component: null, props: {} })
  }

  return (
    <ModalContext.Provider value={{ modal, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = (): ModalContextValue => useContext(ModalContext);