import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps, Pagination, Search, SearchProps, Table } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import _ from 'lodash';
import { ViewSubscriber, Subscriber } from './subscriber.type';
import { ExportCSVButton } from '../../Button/ExportCSV';
import { ActionButtons } from '../../Button/ActionButtons';
import { useModal } from '../../../contexts/ModalContext';
import { DeleteSubscriber } from './DeleteSubscriber';

type Sortable = {
  column: string | null;
  direction: 'ascending' | 'descending' | undefined;
}

type PagProps = {
  numShow: number;
  currentPage: number;
}

type Filters = {
  search: string | null;
}

const showDropdownOptions = [
  {
    key: 1,
    text: '10',
    value: 10
  },
  {
    key: 2,
    text: '20',
    value: 20
  },
  {
    key: 3,
    text: '50',
    value: 50
  },
];

const mapRawToViewData = (data: Subscriber[]): ViewSubscriber[] => {
  return data.map(entry => ({
    ...entry,
    name: entry.firstName + ' ' + entry.lastName
  }))
}

export const ListSubscribers: React.FC = () => {
  const [subscribers, setSubscribers] = useState([] as ViewSubscriber[]);
  const [pagination, setPagination] = useState<PagProps>({ numShow: showDropdownOptions[0].value, currentPage: 1 });
  const [filters, setFilters] = useState<Filters>({ search: null });
  const [sortable, setSortable] = useState<Sortable>({
    column: null,
    direction: undefined
  });
  const { column: sortingColumn, direction } = sortable;
  const { numShow, currentPage } = pagination;

  // Hooks
  const { callApi } = useApi();
  const { showModal } = useModal();

  const changeSort = (columnName: keyof ViewSubscriber): void => {
    if (sortingColumn === columnName) {
      setSortable({ ...sortable, direction: direction === 'ascending' ? 'descending' : 'ascending' });
      return setSubscribers(subscribers.reverse());
    }

    setSortable({
      column: columnName,
      direction: 'ascending'
    });
    return setSubscribers(_.sortBy(subscribers, columnName));
  }

  const onSearching = (event: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
    setFilters({ ...filters, search: data.value || null });
    resetToFirstPage();
  }

  const onNumShowChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setPagination({ currentPage: 1, numShow: data?.value as number });
  }

  const resetToFirstPage = () => {
    setPagination({ ...pagination, currentPage: 1 });
  }

  const applyFilters = (subscriber: ViewSubscriber): boolean => {
    if (filters.search) {
      if (!Object.values(subscriber)
        .map(value => value.toString().toLocaleLowerCase())
        .some(value => filters.search && value.toString().includes(filters.search.toLowerCase()))
      ) return false;
    }

    return true;
  }

  useEffect(() => {
    callApi.get<Subscriber[]>('/subscriber')
      .then(({ data }) => {
        const viewData = mapRawToViewData(data);

        setSubscribers(viewData);
      })
  }, [callApi]);

  return (
    <>
      <h1>EDM</h1>
      <Search className="search-bar" onSearchChange={onSearching} showNoResults={false} size="mini" />

      <div className="table-header-right">
        <ExportCSVButton
          data={subscribers}
          headers={subscribers[0] && Object.keys(subscribers[0]).filter(key => ['id', 'email', 'firstName', 'lastName'].includes(key))}
          filename={`EDM_${Date.now()}.csv`}
        />

        <Dropdown
          text={`Show: ${numShow}`}
          button
          selection compact
          options={showDropdownOptions}
          defaultValue={showDropdownOptions[0].value}
          onChange={onNumShowChange}
        />

        <Pagination
          className="pagination-bar"
          totalPages={Math.ceil(subscribers.filter(applyFilters).length / numShow)}
          siblingRange={1}
          boundaryRange={0}
          ellipsisItem={null}
          onPageChange={(_e, data) => setPagination({ ...pagination, currentPage: data.activePage as number })}
          activePage={currentPage}

        />
      </div>

      <Table sortable striped columns={5}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={() => changeSort('id')}
              sorted={sortingColumn === 'id' ? direction : undefined}
            >
              ID
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => changeSort('email')}
              sorted={sortingColumn === 'email' ? direction : undefined}
            >
              Email
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => changeSort('name')}
              sorted={sortingColumn === 'name' ? direction : undefined}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell>
              Action
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {subscribers
            .filter(applyFilters)
            .slice((currentPage - 1) * numShow, currentPage * numShow).map(subscriber => {

              return (
                <React.Fragment key={subscriber.id}>
                  <Table.Row>
                    <Table.Cell> {subscriber.id}</Table.Cell>
                    <Table.Cell> {subscriber.email}</Table.Cell>
                    <Table.Cell>{subscriber.name}</Table.Cell>
                    <Table.Cell>
                      <ActionButtons actions={['delete']} elementId={subscriber.id} onDeleteClick={() => showModal(DeleteSubscriber, { id: subscriber.id })} />
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              )
            })}
        </Table.Body>
      </Table>
    </>
  );

}