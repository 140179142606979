import React, { useEffect, useState } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { Form, Icon, Input, Label } from 'semantic-ui-react';
import { validateEmail } from '../../utils';

interface EmailListController<T> {
    formContext: UseFormReturn<T, object>;
    name: any;
}


export default function EmailListController<T>({ name, formContext }: EmailListController<T>) {
    const { control, setValue, getValues, setError, clearErrors, formState: { errors } } = formContext;
    const [addingEmail, setAddingEmail] = useState('');
    const {
        field,
    } = useController({
        name,
        control,
    });


    const emailList = getValues(name) as string[] || [];
    const onAddEmailClick = async () => {
        if (emailList.includes(addingEmail)) {
            setAddingEmail('');
            return
        }

        emailList.push(addingEmail)
        setValue(name, emailList as any)
        setAddingEmail('');
    }

    const onRemoveEmail = (removeItem) => {
        const filter = emailList.filter(e => e !== removeItem)
        setValue(name, filter as any)
    }

    const errorInput = !validateEmail(addingEmail) && addingEmail.length > 0

    return <>
        <Form.Field
            error={errorInput}
        >
            <label>Add admin emails</label>
            <Input
                action={{ icon: 'plus', onClick: () => onAddEmailClick(), disabled: errorInput || addingEmail.length === 0 }}
                placeholder="johndoe@logitech.com"
                value={addingEmail}
                onChange={e => setAddingEmail(e.target.value)}
            />
        </Form.Field>
        {emailList.map((email, key) =>
            <Label key={key}
                removeIcon={<Icon name="delete" />}
                onRemove={() => onRemoveEmail(email)}
                content={email}
            />)
        }
    </>
}