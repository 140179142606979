import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Message, Segment, Table } from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { useModal } from "../../../contexts/ModalContext";
import { ActionButtons } from "../../Button/ActionButtons";
import { DeleteCategory } from "./DeleteCategory";
import { ProductCategory } from "./product.type";

export const ListProductCategories: React.FC = () => {
  const [categories, setCategories] = useState([] as ProductCategory[]);
  const { callApi } = useApi();
  const { showModal } = useModal();

  useEffect(() => {
    callApi.get<ProductCategory[]>("/productCategory").then(({ data }) => {
      setCategories(data);
    });
  }, [callApi]);

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Product Categories</h1>
          <Message info size="small">
            Product Categories that will appear in submission form
          </Message>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${window.location.pathname}/new`}>
            <Button color="green">New product</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Segment>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {categories.map((category) => (
              <Table.Row key={category.id}>
                <Table.Cell width="13">{category.name}</Table.Cell>
                <Table.Cell>
                  <ActionButtons
                    actions={["edit", "delete"]}
                    elementId={category.id}
                    onDeleteClick={() =>
                      showModal(DeleteCategory, {
                        id: category.id,
                        name: category.name,
                      })
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};
