import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<{ id: string }> {

}

export const ShowProduct: React.FC<Props> = ({ match }) => {
  return (
    <div>
      Show Product {match.params.id}
    </div>
  );
}