import React from 'react';
import { Route } from 'react-router-dom'
import { EditMx } from '../components/Main/Landingpages/Mx';
import { EditReward } from '../components/Main/Landingpages/Reward';
import { LandingTab } from '../components/Main/Landingpages/LandingTab';
import { ContactList } from '../components/Main/ContactList/ContactList';
import { TTab } from '../components/Main/ContactList/Tab';

export const LandingPagesRoutes = () => {
    return (
        <>
            <Route path='/landingpages/mx/setting' exact component={EditMx} />
            {/* <Route path='/landingpages/reward/setting' exact component={EditReward} /> */}
            <Route path='/landingpages/setting' exact component={LandingTab} />
            <Route path='/landingpages/:id/contactList' exact component={ContactList} />

            <Route path='/landingpages/contactList' exact component={TTab} />
        </>
    )
}