import React, { useRef, useState } from 'react';
import { Button, ButtonGroup, Image as SmtImage, Message } from 'semantic-ui-react';
import { UploadImage } from '../../types/fileTypes';

export interface ImagesProps {
  onSuccess: (name: string, file: UploadImage, files: FileList) => void;
  name: string;
  src?: string;
  maxSize?: number;
  single?: boolean;
  onRemove?: (name: string) => void;
}

class Image {
  public src: string | ArrayBuffer | null = '';
  private data: File | undefined;

  getUploadable(): Promise<UploadImage> {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();

      if (this.data) {
        reader.addEventListener("load", () => { reader.result && this.data && resolve({ data: reader.result, type: this.data.type }) });
        reader.readAsArrayBuffer(this.data);
      }
    });
  }

  toDataUrl() {
    const reader = new FileReader();

    if (this.data) {
      reader.addEventListener("load", () => { this.src = reader.result });
      reader.readAsDataURL(this.data);
    }
  }

  static fromFile(file: File) {
    const image = new Image();
    image.data = file;

    return image;
  }

  static fromSrc(src: string): Image {
    const image = new Image();
    image.src = src;

    return image;
  }
};

export const Images: React.FC<ImagesProps> = ({ onSuccess, name, src: initSrc, maxSize = 5000000, single = true, onRemove }) => {
  const [selectedImages, setSelectedImages] = useState<Image[]>(initSrc ? [Image.fromSrc(initSrc)] : []);
  const [error, setError] = useState<string>();

  const onUpload = async (files: FileList) => {
    for (let i = 0; i < files.length; ++i) {
      const file = files.item(i);

      if (!file) return;

      if (file.size && file.size > maxSize) {
        alert('File too large.');
        continue;
      }

      const newImage = Image.fromFile(file);

      newImage.toDataUrl();

      if (single) {
        setSelectedImages([newImage]);
      } else {
        setSelectedImages([...selectedImages, newImage])
      }

      onSuccess(name, await newImage.getUploadable(), files);
    }
  }

  const onRemoveImages = () => {
    setSelectedImages([]);
    onRemove && onRemove(name);
  }

  return (
    <div>
      <SmtImage.Group>
        {selectedImages.map((image, key) => <SmtImage key={key} src={image.src} size="small" bordered />)}
      </SmtImage.Group>
      {error && <Message negative onDismiss={() => setError(undefined)}>{error}</Message>}
      <ButtonGroup >
        <UploadButton onUpload={onUpload} onError={setError} />
        {selectedImages.length ? <Button icon="trash" onClick={onRemoveImages} /> : null}
      </ButtonGroup>
    </div>
  );
}

type UploadButtonProps = {
  onUpload: (files: FileList) => void;
  onError: (msg: string) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ onUpload, onError }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const VALID_TYPE = ['jpg', 'jpeg', 'png'];

  const isValidImageType = (type: string): boolean => {
    return VALID_TYPE.some(extension => type.includes(extension));
  }

  return (
    <Button onClick={() => { ref.current?.click() }}>
      <input
        type="file"
        ref={ref}
        hidden
        accept={VALID_TYPE.map(type => `.${type}`).join(',')}
        onChange={e => {
          if (e.target.files) {
            let error = false;

            for (let i = 0; i < e.target.files.length; ++i) {
              const fileType = e.target.files.item(i)?.type || '';

              if (!isValidImageType(fileType)) {
                error = true;
                break;
              }
            }

            error ? onError('Invalid image type. Allow jpg, jpeg, png.') : onUpload(e.target.files)
          }
        }} />
      Add image
    </Button>
  )
}