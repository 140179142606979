import React, { useCallback, useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Button,
  Grid,
  Table,
  Image,
  Segment,
  Dropdown,
  Search,
  Pagination,
  Label,
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { PromoCode } from "./promoCode.type";
import debounce from "lodash/debounce";

const showDropdownOptions = [
  {
    key: 1,
    text: "10",
    value: 10,
  },
  {
    key: 2,
    text: "20",
    value: 20,
  },
  {
    key: 3,
    text: "50",
    value: 50,
  },
  {
    key: 4,
    text: "100",
    value: 100,
  },
];

export const ListPromoCodes: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [oriLists, setOriLists] = useState([] as PromoCode[]);
  const [filteredList, setFilteredList] = useState([] as PromoCode[]);
  const [displayList, setDisplayList] = useState([] as PromoCode[]);

  const { callApi } = useApi();

  const [statusFilter, setStatusFilter] = useState(false);

  const [searching, setSearching] = useState<string>("");
  const [pagination, setPagination] = useState({
    numShow: showDropdownOptions[0].value,
    currentPage: 1,
  });
  const { numShow, currentPage } = pagination;

  useEffect(() => {
    setLoading(true);
    const status = Number(statusFilter);
    callApi
      .get<PromoCode[]>("/promocode", {
        params: {
          status,
        },
      })
      .then(({ data }) => {
        setOriLists(data);
      })
      .finally(() => setLoading(false));
  }, [callApi, statusFilter]);

  useEffect(() => {
    if (searching) {
      setFilteredList(
        oriLists.filter((p) => p.code.includes(searching.trim().toLowerCase()))
      );

      setPagination({
        currentPage: 1,
        numShow,
      });
    } else setFilteredList([...oriLists]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oriLists, searching]);

  useEffect(() => {
    let result = [...filteredList];

    const startIndex = currentPage * numShow - numShow;
    const endIndex = startIndex + numShow;
    const display = result.slice(startIndex, endIndex);
    setDisplayList(display);
  }, [currentPage, filteredList, numShow]);

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Promo Code</h1>
        </Grid.Column>
      </Grid>
      <Segment>
        <Grid columns={2} stackable>
          <Grid.Column>
            <Search
              className="search-bar"
              onSearchChange={debounce(
                (_, data) => setSearching(data?.value),
                500
              )}
              showNoResults={false}
              size="mini"
            />
            <Dropdown
              text={statusFilter ? "Assigned" : "Unassigned"}
              icon="filter"
              labeled
              button
              className="icon"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text={"Assigned"}
                  active={statusFilter}
                  value={true}
                  onClick={() => {
                    setStatusFilter(true);
                  }}
                />
                <Dropdown.Item
                  text={"Unassigned"}
                  active={!statusFilter}
                  value={true}
                  onClick={() => {
                    setStatusFilter(false);
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Dropdown
              text={`Show: ${numShow}`}
              button
              selection
              compact
              options={showDropdownOptions}
              defaultValue={showDropdownOptions[0].value}
              onChange={(_e, data) => {
                setPagination({
                  currentPage: 1,
                  numShow: data?.value as number,
                });
              }}
            />
            <Pagination
              disabled={filteredList.length == 0}
              className="pagination-bar"
              totalPages={Math.ceil(filteredList.length / numShow)}
              siblingRange={1}
              boundaryRange={0}
              ellipsisItem={null}
              onPageChange={(_e, data) =>
                setPagination({
                  currentPage: data.activePage as number,
                  numShow,
                })
              }
              activePage={currentPage}
            />
          </Grid.Column>
        </Grid>

        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Participant Id</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {displayList.map((p) => (
              <Table.Row key={p.id}>
                <Table.Cell>{p.id}</Table.Cell>
                <Table.Cell>{p.code}</Table.Cell>
                <Table.Cell>{p.Participant?.email}</Table.Cell>
                <Table.Cell>{p.participantId}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Grid columns={1} stackable style={{ marginTop: "1em" }}>
          <Grid.Column>
            <Label attached="bottom right">{`Total : ${oriLists.length}`}</Label>
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};

