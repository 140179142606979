import React from 'react';
import { Grid, Image, List, Icon } from 'semantic-ui-react';
import { useModal } from '../../../contexts/ModalContext';
import { ImageModal } from '../../Modal/ImageModal';
import { ViewParticipant } from './participant.type';
import './ViewParticipant.css'

type Props = {
  data: ViewParticipant
}

export const ParticipantView: React.FC<Props> = ({ data }) => {
  const { email, firstName, lastName, model, productType, serialNumber, reseller, receiptNumber, receipt, phoneNo, } = data;
  const { showModal } = useModal();

  const ShowImageOrPdf = (props: { receipt: string }) => {
    if (receipt.endsWith('.pdf')) return (
      <a href={receipt} target="_blank">
        <Icon name="file pdf" />
      </a>
    )
    return (
      <Image src={receipt} size="small" onClick={() => showModal(ImageModal, { src: receipt })} />
    )
  }
  return (
    <Grid columns={16}>
      <Grid.Column width={2} textAlign="right">
        <List>
          <List.Item style={data.emailSuspicious ? { color: 'red' } : {}}><strong>Email:</strong></List.Item>
          <List.Item><strong>Firstname:</strong></List.Item>
          <List.Item><strong>Lastname:</strong></List.Item>
          <List.Item><strong>Product type:</strong></List.Item>
          <List.Item><strong>Model:</strong></List.Item>
          <List.Item><strong>Phone No:</strong></List.Item>
        </List>
      </Grid.Column>
      <Grid.Column width={3}>
        <List>
          <List.Item style={data.emailSuspicious ? { color: 'red' } : {}}>{email}</List.Item>
          <List.Item>{firstName}</List.Item>
          <List.Item>{lastName}</List.Item>
          <List.Item>{productType}</List.Item>
          <List.Item>{model}</List.Item>
          <List.Item>{phoneNo}</List.Item>
        </List>
      </Grid.Column>
      <Grid.Column width={3} textAlign="right">
        <List>
          <List.Item><b style={data.serialNumberSuspicious ? { color: 'red' } : {}}>Product S/N:</b></List.Item>
          <List.Item><b>Reseller:</b> </List.Item>
          <List.Item><b style={data.receiptNumberSuspicious ? { color: 'red' } : {}}>Receipt No.:</b></List.Item>
          {data.dob ? <List.Item><b>DOB:</b></List.Item> : ""}
          {data.gender ? <List.Item><b>Gender:</b></List.Item> : ""}
        </List>
      </Grid.Column>
      <Grid.Column width={3}>
        <List>
          <List.Item style={data.serialNumberSuspicious ? { color: 'red' } : {}}>{serialNumber}</List.Item>
          <List.Item >{reseller}</List.Item>
          <List.Item style={data.receiptNumberSuspicious ? { color: 'red' } : {}}>{receiptNumber}</List.Item>
          {data.dob ? <List.Item>{data.dob.split('T')[0]}</List.Item> : ""}
          {data.gender ? <List.Item>{data.gender}</List.Item> : ""}
        </List>
      </Grid.Column>
      <Grid.Column width={5}>
        <label><b>Receipt</b></label>
        <ShowImageOrPdf receipt={receipt} />
      </Grid.Column>
    </Grid>
  );
}
