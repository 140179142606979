import React, { useEffect, useState } from 'react';
import { RichEditor } from '../../Input/RichEditor';
import { useApi } from '../../../contexts/ApiContext';
import { Button, Menu, MenuItemProps, Message } from 'semantic-ui-react';
import { AxiosResponseError } from '../../../types/axios';

export type Templates = {
  approve?: string;
  reject?: string;
  register?: string;
}

export const EmailTemplate: React.FC = () => {
  const [editors, setEditors] = useState<Templates>({} as Templates);
  const [activeEditor, setActiveEditor] = useState<keyof Templates>("approve");
  const [error, setError] = useState<AxiosResponseError>();
  const [message, setMessage] = useState<string>();
  const { callApi } = useApi();

  useEffect(() => {
    callApi.get<Templates>('/template')
      .then(({ data }) => setEditors(data))
  }, [callApi]);

  function onEditorChange(name: string, content: string) {
    setEditors(prev => ({ ...prev, [name]: content }));
  }

  const onActiveEditorClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, { id }: MenuItemProps) => {
    setActiveEditor(id);
  }

  const onSubmit = async () => {
    try {
      await callApi.post('/template', editors).then(() => {
        setMessage('Success');
        setTimeout(() => setMessage(undefined), 2000);
      });
    } catch (e) {
      setError(e);
    }
  }

  const renderMessage = () => {
    console.log(editors);
    return (
      <>
        <Message hidden={!error} negative>
          <p>{error?.response.data || 'Some thing went wrong'}</p>
        </Message>
        <Message hidden={!message} positive>
          <p>{message}</p>
        </Message>
      </>
    )
  }

  return (
    <>
      <h1>Email template</h1>
      {renderMessage()}
      <Menu pointing secondary>
        <Menu.Item id='approve' name='Approve participant' active={activeEditor === 'approve'} onClick={onActiveEditorClick} />
        <Menu.Item id='reject' name='Reject participant' active={activeEditor === 'reject'} onClick={onActiveEditorClick} />
        <Menu.Item id='register' name='Success product register' active={activeEditor === 'register'} onClick={onActiveEditorClick} />
      </Menu>
      <RichEditor key={activeEditor} name={activeEditor} content={editors[activeEditor]} onChange={onEditorChange} />
      <Button color="vk" onClick={onSubmit} style={{ marginTop: '20px' }}>Save</Button>
    </>
  )
}