import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Form,
  Grid,
  InputOnChangeData,
  Message,
  DropdownProps,
  Segment,
  FormRadio,
  Button,
  Label,
  Menu,
  Icon,
  Confirm,
  Accordion,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { AxiosResponseError } from "../../../types/axios";
import axios from "axios";
import { UploadImage } from "../../../types/fileTypes";
import EmailListController from "../../Input/EmailListController";
import { LandingPages, LandingPagesAll, AllReseller } from "./Reward.type";
import _, { values } from "lodash";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
  DeepMap,
  FieldError,
  useController,
  useFormState,
} from "react-hook-form";
import ReactQuill from "react-quill";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Images } from "../../Input/Images";
import { RichEditorController } from "../../Input/RichEditorController";
import DropdownActionWrap from "../../Input/DropdownActionWrap";

export interface FormProps extends Omit<LandingPages, "detail"> {
  // detail: {
  //     banner?: UploadImage;
  //     mobile_banner?: UploadImage;
  // };
}

type FormErrors = {
  [key in keyof FormProps]?: string;
};

type ErrorsType = DeepMap<LandingPages, FieldError> & {
  [index: string]: any;
};

const schema = yup
  .object({
    // contact_list_id: yup.string().required("Contact list id is required"),
    Resellers: yup
      .array()
      .required("Stores & Resellers is required")
      .test("NotEmptyArr", "Stores & Resellers is required", function (v) {
        if (v.length === 0) {
          return false;
        }
        return true;
      }),
    OfficialProductCategory: yup
      .array()
      .required("Official product cateogory is required")
      .test(
        "NotEmptyArr",
        "Official product cateogory is required",
        function (v) {
          if (v.length === 0) {
            return false;
          }
          return true;
        }
      ),
    approve_email_template: yup
      .string()
      .required("Approve template is required"),
    rejected_email_template: yup
      .string()
      .required("Reject template is required"),
    maxDailyContestSubmissions: yup
      .number()
      .typeError("Max daily contest submissions must be a number")
      .positive("Max daily contest submissions greater than zero")
      .required("Max daily lucky draw submission is required"),
    // privacy: yup.string().required('Privacy is required'),
    // Banners: yup
    //   .array()
    //   .required("Banner is required")
    //   .test("NotEmptyArr", "Banner is required", function (v) {
    //     if (v.length === 0) {
    //       return false;
    //     }
    //     return true;
    //   }),
  })
  .required();

type Props = {
  id?: number;
};

export const EditReward: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const formContext = useForm<LandingPages>({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    setError,
    watch,
    reset,
    control,
  } = formContext;
  const { errors, isDirty } = useFormState({ control });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [error, setErrors] = useState<AxiosResponseError | null>(null);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [redirectingTo, setRedirectingTo] = useState("");
  const [allReseller, setAllReseller] = useState<AllReseller[]>();
  const [allOfficialProductCategory, setAllOfficialProductCategory] =
    useState<AllReseller[]>();
  const [campaignBannerOri, setCampaignBannerOri] =
    useState<{ seoImage: string }>();
  const { field: seoImage } = useController({
    name: "seoImage",
    control,
    defaultValue: undefined,
  });

  const { callApi } = useApi();
  useEffect(() => {
    setFormLoading(true)
    callApi
      .get<LandingPagesAll>("landing/" + id)
      .then(({ data }) => {
        const item = {
          ...data.landing,
          // ...JSON.parse(data.landing.detail),
        };

        const { seoImage, ...rest } = item;
        setCampaignBannerOri({ seoImage: seoImage as unknown as string });
        reset({
          ...rest, approve_email_template: rest.approve_email_template ? rest.approve_email_template : "",
          rejected_email_template: rest.rejected_email_template ? rest.rejected_email_template : "",
          adminEmailTemplate: rest.adminEmailTemplate ? rest.adminEmailTemplate : "",
          seoTitle: rest.seoTitle ? rest.seoTitle : "",
          seoDesc: rest.seoDesc ? rest.seoDesc : "",
          seoKeyword: rest.seoKeyword ? rest.seoKeyword : "",
          privacy: rest.privacy ? rest.privacy : "<p><p/>",
        });
        setAllOfficialProductCategory(data.officialCategory);
        setAllReseller(data.resellers);
        // setAllBanners(data.banners);
      })
      .catch((e) => {
        console.log("api err", e);
        if (e.response.status !== 404) {
          console.log(e.message);
          setErrors(e);
        }
      });
    setFormLoading(false)
  }, [callApi]);

  useEffect(() => {
    console.log(errors);
    formValidate();
  }, [Object.keys(errors).length]);

  useEffect(() => {
    console.log("isDirty", isDirty);
    if (redirectingTo) {
      if (!isDirty) {
        history.push(redirectingTo);
      }
    }
  }, [redirectingTo, isDirty, history]);

  const formValidate = (): boolean => {
    if (loading === true) {
      return false;
    }
    let isValid = true;
    const formErrors: FormErrors = {};
    const parsedErrors = errors as ErrorsType;

    Object.keys(errors).forEach((k) => {
      const val = parsedErrors[k];
      formErrors[k] = val.message;
    });
    window.scrollTo(0, 0);

    setFormErrors({ ...formErrors });
    return isValid;
  };

  const onSubmit = async () => {
    try {
      
      if (loading === true) {
        return;
      }

      setLoading(true);
      const data = getValues();

      const { ...formData } = data;

      const params = new FormData();
      if (formData.seoImage) {
        const img = formData.seoImage[0];
        //@ts-ignore
        params.append("seoImage", img);
      }
      //@ts-ignore
     
      params.append("Resellers", JSON.stringify(formData.Resellers));
      //@ts-ignore
      params.append(
        "OfficialProductCategory",
        JSON.stringify(formData.OfficialProductCategory)
      );
      //@ts-ignore
      // params.append("Banners", JSON.stringify(formData.Banners));
      params.append("privacy", formData.privacy);
      params.append("approve_email_template", formData.approve_email_template);
      params.append(
        "rejected_email_template",
        formData.rejected_email_template
      );
      params.append("contact_list_id", formData.contact_list_id);

      params.append(
        "maxDailyContestSubmissions",
        formData.maxDailyContestSubmissions.toString()
      );
      params.append("adminEmailTemplate", formData.adminEmailTemplate);
      if (formData.adminEmails) {
        params.append("adminEmails", JSON.stringify(formData.adminEmails));
      }

      params.append("seoTitle", formData.seoTitle);
      params.append("seoDesc", formData.seoDesc);
      params.append("seoKeyword", formData.seoKeyword);
      params.append("landingId", "2");
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      await callApi.patch(`/landing/` + id, params, config);
      setLoading(false);
      history.go(0);
    } catch (e) {
      setLoading(false);
      setErrors(e);
    }
  };

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(formErrors).length} negative>
          <p>{formErrors[Object.keys(formErrors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || "Some thing went wrong"}</p>
        </Message>
      </>
    );
  };

  return (
    <div>
      {formLoading && <Dimmer active={formLoading} key={id + "dimmer"}>
        <Loader />
      </Dimmer>}
      <h1>Settings</h1>
      {renderError()}
      <Form style={{ paddingLeft: 10 }}>
        <Accordion
          fluid
          defaultActiveIndex={0}
          panels={[
            {
              key: "content",
              title: "Content",
              content: {
                content: (
                  <Segment>
                    {/* <Controller
                      control={control}
                      name="Banners"
                      render={({ field }) => {
                        return (
                          <Form.Dropdown
                            width="16"
                            label="Banners"
                            placeholder="Banners"
                            key={allBanners?.length + "banners"}
                            {...field}
                            value={field.value || []}
                            onChange={(e, { name, value }) => {
                              setValue(name, value);
                            }}
                            ref={undefined}
                            fluid
                            multiple
                            search
                            selection
                            options={allBanners || []}
                          />
                        );
                      }}
                    /> */}

                    <Controller
                      control={control}
                      name="OfficialProductCategory"
                      render={({ field }) => {
                        return (
                          <DropdownActionWrap
                            onActionClick={() => {
                              setRedirectingTo("/officialProductCategory");
                            }}
                          >
                            <Form.Dropdown
                              width="16"
                              label="Official Product Cateogory"
                              placeholder="Official Product Cateogory"
                              key={allOfficialProductCategory?.length + "opc" + id}
                              {...field}
                              value={field.value || []}
                              onChange={(e, { name, value }) => {
                                console.log("on change", value);
                                setValue(name, value);
                              }}
                              ref={undefined}
                              fluid
                              multiple
                              search
                              selection
                              options={allOfficialProductCategory || []}
                            />
                          </DropdownActionWrap>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="Resellers"
                      render={({ field }) => {
                        return (
                          <DropdownActionWrap
                            onActionClick={() => {
                              setRedirectingTo("/reseller");
                            }}
                          >
                            <Form.Dropdown
                              width="16"
                              label="Stores & Resellers"
                              placeholder="Stores & Resellers"
                              key={allReseller?.length + "reseller" + id}
                              {...field}
                              value={field.value || []}
                              onChange={(e, { name, value }) => {
                                setValue(name, value);
                              }}
                              ref={undefined}
                              fluid
                              multiple
                              search
                              selection
                              options={allReseller || []}
                            />
                          </DropdownActionWrap>
                        );
                      }}
                    />
                  </Segment>
                ),
              },
            },
            {
              key: "pp" + id,
              title: "Privacy Policy",
              content: {
                content: (
                  <Segment>
                    <RichEditorController name={"privacy"} control={control} />
                  </Segment>
                ),
              },
            },
            {
              key: "emailTemplates" + id,
              title: "Email Template Settings",
              content: {
                content: (
                  <Segment>
                    <Message info>
                      <p>These template ids refer to Send Grid's Templates</p>
                    </Message>

                    <Controller
                      control={control}
                      name="approve_email_template"
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Input
                          label="Approved Template Id"
                          width="16"
                          {...field}
                          ref={undefined}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="rejected_email_template"
                      // rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Form.Input
                            label="Rejected Template Id"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        );
                      }}
                    />
                    <div style={{ display: "none" }}>
                      <Controller
                        control={control}
                        name="contact_list_id"
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Form.Input
                            label="Contact List"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="adminEmailTemplate"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Form.Input
                          label="Notify Admin Template Id"
                          width="16"
                          {...field}
                          ref={undefined}
                        />
                      )}
                    />
                  </Segment>
                ),
              },
            },
            {
              key: "seo" + id,
              title: "SEO",
              content: {
                content: (
                  <Segment>
                    <Form.Group widths="equal">
                      <Controller
                        control={control}
                        name="seoTitle"
                        render={({ field }) => (
                          <Form.Input
                            label="Title"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="seoKeyword"
                        render={({ field }) => (
                          <Form.Input
                            label="Keyword"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        )}
                      />
                    </Form.Group>

                    <Controller
                      control={control}
                      name="seoDesc"
                      render={({ field }) => (
                        <Form.TextArea
                          label="Description"
                          width="16"
                          {...field}
                          ref={undefined}
                        />
                      )}
                    />
                    <Form.Field style={{ flexDirection: "column" }}>
                      <label>
                        <b>Image</b>
                      </label>
                      <Images
                        key={campaignBannerOri?.seoImage}
                        src={campaignBannerOri?.seoImage}
                        name={seoImage.name}
                        onSuccess={(
                          name: string,
                          file: UploadImage,
                          files: FileList
                        ) => {
                          setValue("seoImage", null);
                          setValue("seoImage", files);
                        }}
                        onRemove={() => {
                          setValue("seoImage", null);
                        }}
                      />
                    </Form.Field>
                  </Segment>
                ),
              },
            },
            {
              key: "others" + id,
              title: "Others",
              content: {
                content: (
                  <Grid columns={3}>
                    <Grid.Column>
                      <Segment>
                        <Controller
                          control={control}
                          name="maxDailyContestSubmissions"
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Form.Input
                              label="Max daily lucky draw submission"
                              width="16"
                              {...field}
                              ref={undefined}
                            />
                          )}
                        />
                        <EmailListController<LandingPages>
                          formContext={formContext}
                          name="adminEmails"
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid>
                ),
              },
            },
          ]}
        />
        <Form.Button
          style={{ marginTop: "50px" }}
          color="vk"
          onClick={handleSubmit(onSubmit)}
          type="submit"
          loading={loading}
        >
          Save
        </Form.Button>
      </Form>

      <Confirm
        open={!!redirectingTo}
        content="Unsaved changes will be lost, are you sure to navigate away ?"
        onCancel={() => {
          setRedirectingTo("");
        }}
        onConfirm={() => {
          history.push(redirectingTo);
        }}
      />
    </div>
  );
};
