import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import jwt from 'jsonwebtoken';

type User = {
  name?: string;
} | null;

type IUserContextValue = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const UserContext = createContext({} as IUserContextValue);

export const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState({} as User);
  const { authTokens } = useAuth();

  useEffect(() => {
    setUser({
      ...user,
      name: (jwt.decode(authTokens?.accessToken || '') as any)?.username
    })
  }, [authTokens])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
};

export const useUser = (): IUserContextValue => {
  return useContext(UserContext);
}