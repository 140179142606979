import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  InputOnChangeData,
  Message,
  Image,
  Dropdown,
  DropdownProps,
  Segment,
  FormGroup,
  Header,
  Divider,
  Accordion,
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import {
  Campaign,
  textColorOptions,
  AllPrizes,
  CampaignResult,
  AllProduct,
  RelationListing,
  RewardCampaign,
  RewardCampaignAll,
} from "./campaign.type";
import moment from "moment";
import _, { String, values } from "lodash";
import { Link, useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosResponseError } from "../../../types/axios";
import { Images } from "../../Input/Images";
import { UploadImage } from "../../../types/fileTypes";
import "./Campaign.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
  DeepMap,
  FieldError,
  useController,
} from "react-hook-form";
import ReactQuill from "react-quill";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { start } from "repl";
import { RichEditorController } from "../../Input/RichEditorController";
import DDropdown from "../../Input/DDropdown";

interface Props extends RouteComponentProps<{ id: string, landingId: string }> { }

export interface FormProps extends Omit<Campaign, "id"> { }

type FormErrors = {
  [key in keyof FormProps]?: string;
};

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    // description: yup.string().required("Description is required"),
    startDate: yup.string().required("Campaign Start Date is required"),
    endDate: yup.string().required("Campaign end date is required"),
    purchaseStartDate: yup.string().required("Purchase start date is required"),
    purchaseEndDate: yup.string().required("Purchase end date is required"),
    resultDate: yup.string().required("Result date is required"),
    slug: yup.string().required("Slug is required"),
    seoTitle: yup.string().required("SEO title is required"),
    seoImage: yup
      .mixed()
      .test("testnull", "SEO image is required", function (v) {
        if (v === null) {
          return false;
        }
        return true;
      }),
    Prizes: yup
      .array()
      .required("Prizes is required")
      .test("NotEmptyArr", "Prizes is required", function (v) {
        if (!v || (v && v.length === 0)) {
          return false;
        }
        return true;
      }),
    Products: yup
      .array()
      .required("Products is required")
      .test("NotEmptyArr", "Products is required", function (v) {
        if (!v || (v && v.length === 0)) {
          return false;
        }
        return true;
      }),
  })
  .test(
    "testDate",
    "Campaign start date cannot larger than campaign end date",
    function (value) {
      if (moment(value.startDate).isAfter(moment(value.endDate))) {
        return false;
      }
      return true;
    }
  )
  .test(
    "testDate",
    "Purchase start date cannot larger than purchase end date",
    function (value) {
      if (
        moment(value.purchaseStartDate).isAfter(moment(value.purchaseEndDate))
      ) {
        return false;
      }
      return true;
    }
  )
  .required();

const landingMap = {
  "2": "Reward",
  "3": "Mx",
}

export const RewardForm: React.FC<Props> = ({ match }) => {
  const { id, landingId } = match.params;
  const { callApi } = useApi();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [editLanding, setEditLanding] = useState("");
  const [error, setError] = useState<AxiosResponseError | null>(null);
  const [allPrizes, setAllPrizes] = useState<AllPrizes[]>();
  const [allProduct, setAllProduct] = useState<AllProduct[]>();
  const [allBanners, setAllBanners] = useState<AllProduct[]>();
  const [campaignBannerOri, setCampaignBannerOri] =
    useState<{ seoImage: string }>();

  const formContext = useForm<RewardCampaign>({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    control,
  } = formContext

  useEffect(() => {
    formValidate();
  }, [errors]);

  const { field: seoImage } = useController({
    name: "seoImage",
    control,
    defaultValue: id ? undefined : null,
  });

  type ErrorsType = DeepMap<Campaign, FieldError> & {
    [index: string]: any;
  };

  useEffect(() => {
    if (id) {
      callApi
        .get<RewardCampaignAll>(`/campaign/${id}`)
        .then(({ data }) => {
          setAllPrizes(data.prizes);
          setAllProduct(data.products);
          setAllBanners(data.banners);
          [
            "startDate",
            "endDate",
            "purchaseStartDate",
            "purchaseEndDate",
            "resultDate",
          ].forEach((x) => {
            data.campaign[x] = moment(data.campaign[x]).toDate();
          });
          setCampaignBannerOri({
            seoImage: data.campaign.seoImage as unknown as string,
          });
          setEditLanding(data.campaign.landingId)
          const { seoImage, ...rest } = data.campaign;
          reset(rest);
        })
        .catch((e) => {
          if (e.response.status !== 404) {
            setError(e);
          }
        });
    } else {
      callApi
        .get<RelationListing>("/campaign/related-listing")
        .then(({ data }) => {
          setAllPrizes(data.prizes);
          setAllProduct(data.products);
          setAllBanners(data.banners);
        })
        .catch((e) => {
          if (e.response.status !== 404) {
            setError(e);
          }
        });
    }
  }, [callApi]);

  const formValidate = (): boolean => {
    if (loading === true) {
      return false;
    }
    let isValid = true;
    const formErrors: FormErrors = {};
    const parsedErrors = errors as ErrorsType;
    Object.keys(errors).forEach((k) => {
      const val = parsedErrors[k];
      formErrors[k] = val.message;
    });
    window.scrollTo(0, 0);

    setFormErrors({ ...formErrors });
    return isValid;
  };

  const formatDate = (date: Date, start: boolean): string => {
    const item =
      moment(date).format("yyyy-MM-DD") + (start ? " 00:00:00" : " 23:59:59");
    return moment(item).utc().format();
  };

  const onSubmit = async () => {
    try {
      if (loading === true) {
        return;
      }

      setLoading(true);
      const data = getValues();

      const { ...formData } = data;

      const params = new FormData();
      if (formData.seoImage) {
        const img = formData.seoImage[0];
        //@ts-ignore
        params.append("seoImage", img);
      }
      params.append("name", formData.name);
      params.append("description", formData.description ?? "-");
      //@ts-ignore
      params.append("Prizes", JSON.stringify(formData.Prizes));
      //@ts-ignore
      params.append("Products", JSON.stringify(formData.Products));
      //@ts-ignore
      params.append("startDate", formatDate(formData.startDate, true));
      //@ts-ignore
      params.append("endDate", formatDate(formData.endDate));
      //@ts-ignore
      params.append(
        "purchaseStartDate",
        formatDate(formData.purchaseStartDate, true)
      );
      //@ts-ignore
      params.append("purchaseEndDate", formatDate(formData.purchaseEndDate));
      //@ts-ignore
      params.append("resultDate", formatDate(formData.resultDate, true));
      //@ts-ignore
      params.append("Banners", JSON.stringify(formData.Banners));
      params.append("tnc", formData.tnc);
      params.append("landingId", landingId ? landingId : editLanding);
      params.append("seoTitle", formData.seoTitle);
      params.append("slug", formData.slug);
      params.append("seoDesc", formData.seoDesc);
      params.append("seoKeyword", formData.seoKeyword);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      if (id) {
        await callApi.put(`/campaign/${id}`, params, config);
      } else {
        await callApi.post(`/campaign`, params, config);
      }
      setLoading(false);
      history.push("/landingpages/campaign");
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };
  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(formErrors).length} negative>
          <p>{formErrors[Object.keys(formErrors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || "Some thing went wrong"}</p>
        </Message>
      </>
    );
  };


  return (
    <div>
      <h1 style={{ marginBottom: 20 }}>
        {" "}
        {id ? "Edit" : "New"} {landingId ? landingMap[landingId] : landingMap[editLanding]} Campaign
      </h1>
      {renderError()}
      <Form>
        <Grid>
          <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
            <Header as="h3" dividing>
              Basic Info
            </Header>
            <Segment>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Form.Input
                    label="Name"
                    width="16"
                    {...field}
                    ref={undefined}
                  />
                )}
              />
              {/* <Controller
                          control={control}
                          name="description"
                          render={({ field }) => (
                            <Form.Input
                              label="Description"
                              width="16"
                              {...field}
                              ref={undefined}
                            />
                          )}
                        /> */}
              <Controller
                control={control}
                name="slug"
                render={({ field }) => (
                  <Form.Input
                    label="Slug"
                    width="16"
                    {...field}
                    ref={undefined}
                  />
                )}
              />
              <Controller
                control={control}
                name="Prizes"
                render={({ field }) => {
                  return (
                    <Form.Dropdown
                      width="16"
                      label="Prizes"
                      // placeholder="Prizes"
                      key={allPrizes?.length + "Prizes"}
                      {...field}
                      value={field.value || []}
                      onChange={(e, { name, value }) => {
                        setValue(name, value);
                      }}
                      ref={undefined}
                      fluid
                      multiple
                      search
                      selection
                      options={allPrizes || []}
                    />
                  );
                }}
              />
              <DDropdown
                name="Products"
                label="Products"
                option={allProduct || []}
                formContext={formContext}
              />
              {/* <Controller
                control={control}
                name="Products"
                render={({ field }) => {
                  return (
                    <Form.Dropdown
                      width="16"
                      label="Products"
                      // placeholder="Products"
                      key={allProduct?.length + "products"}
                      {...field}
                      value={field.value || []}
                      onChange={(e, { name, value }) => {
                        setValue(name, value);
                      }}
                      ref={undefined}
                      fluid
                      multiple
                      search
                      selection
                      options={allProduct || []}
                    />
                  );
                }}
              /> */}

              <Controller
                control={control}
                name="Banners"
                render={({ field }) => {
                  return (
                    <Form.Dropdown
                      width="16"
                      label="Banners"
                      placeholder="Banners"
                      key={allBanners?.length + "banners"}
                      {...field}
                      value={field.value || []}
                      onChange={(e, { name, value }) => {
                        setValue(name, value);
                      }}
                      ref={undefined}
                      fluid
                      multiple
                      search
                      selection
                      options={allBanners || []}
                    />
                  );
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
            <Header as="h3" dividing>
              Campaign Dates
            </Header>
            <Segment>
              <Form.Group>
                <Form.Field>
                  <label>Campaign Start Date</label>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          timeFormat="hh:mm:ss"
                          placeholderText="Start Date"
                          selected={field.value}
                          onChange={(date: Date) => {
                            setValue("startDate", date);
                          }}
                        />
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Campaign End Date</label>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          timeFormat="hh:mm:ss"
                          placeholderText="End Date"
                          selected={field.value}
                          onChange={(date: Date) => {
                            setValue("endDate", date);
                          }}
                        />
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Purchase Start Date</label>
                  <Controller
                    control={control}
                    name="purchaseStartDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          timeFormat="hh:mm:ss"
                          placeholderText="Start Date"
                          selected={field.value}
                          onChange={(date: Date) => {
                            setValue("purchaseStartDate", date);
                          }}
                        />
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Purchse End Date</label>
                  <Controller
                    control={control}
                    name="purchaseEndDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          timeFormat="hh:mm:ss"
                          placeholderText="End Date"
                          selected={field.value}
                          onChange={(date: Date) => {
                            setValue("purchaseEndDate", date);
                          }}
                        />
                      );
                    }}
                  />
                </Form.Field>

              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Result Date</label>
                  <Controller
                    control={control}
                    name="resultDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          timeFormat="hh:mm:ss"
                          placeholderText="Result Date"
                          selected={field.value}
                          onChange={(date: Date) => {
                            setValue("resultDate", date);
                          }}
                        />
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>
          </Grid.Column>
        </Grid>

        <Accordion
          style={{ marginTop: 30 }}
          fluid
          panels={[
            {
              key: "tnc",
              title: "Terms & Conditions",
              content: {
                content: (
                  <Segment>
                    <RichEditorController name={"tnc"} control={control} />
                  </Segment>
                ),
              },
            },
            {
              key: "seo",
              title: "SEO",
              content: {
                content: (
                  <Segment>
                    <Form.Group widths="equal">
                      <Controller
                        control={control}
                        name="seoTitle"
                        render={({ field }) => (
                          <Form.Input
                            label="Title"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="seoKeyword"
                        render={({ field }) => (
                          <Form.Input
                            label="Keyword"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        )}
                      />
                    </Form.Group>

                    <Controller
                      control={control}
                      name="seoDesc"
                      render={({ field }) => (
                        <Form.TextArea
                          label="Description"
                          width="16"
                          {...field}
                          ref={undefined}
                        />
                      )}
                    />
                    <Form.Field style={{ flexDirection: "column" }}>
                      <label>
                        <b>Image</b>
                      </label>
                      <Images
                        key={campaignBannerOri?.seoImage}
                        src={campaignBannerOri?.seoImage}
                        name={seoImage.name}
                        onSuccess={(
                          name: string,
                          file: UploadImage,
                          files: FileList
                        ) => {
                          setValue("seoImage", null);
                          setValue("seoImage", files);
                        }}
                        onRemove={() => {
                          setValue("seoImage", null);
                        }}
                      />
                    </Form.Field>
                  </Segment>
                ),
              },
            },
          ]}
        />

        <Form.Button
          style={{ marginTop: "50px" }}
          color="vk"
          onClick={handleSubmit(onSubmit)}
          type="submit"
          loading={loading}
        >
          Save
        </Form.Button>
      </Form>
    </div>
  );
};
