import React from 'react';
import { Login } from './components/Login/Login';
import { Main } from './components/Main/Main';
import { useAuth } from './contexts/AuthContext';
import './components/css/global.css';


const App: React.FC = () => {
  const { authTokens: isLogin } = useAuth();

  return (
    !!isLogin ? <Main /> : <Login />
  );
}

export default App;
