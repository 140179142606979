import React, { FC } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
interface DropdownActionWrapProps {
  onActionClick: () => void;
}
const DropdownActionWrap: FC<DropdownActionWrapProps> = ({
  onActionClick,
  children,
}) => {
  return (
    <Form.Group>
      {children}
      {/* <Button
        icon
        style={{ marginTop: "auto", height: 38 }}
        onClick={onActionClick}
      >
        <Icon name="add" />
      </Button> */}
    </Form.Group>
  );
};

export default DropdownActionWrap;
