import React from 'react';
import { Sidebar } from 'semantic-ui-react';
import { NavBar } from '../NavBar/NavBar';
import { SideBar } from '../SideBar/SideBar';
import './Main.css';
import { CampaignRoutes } from '../../routes/campaigns';
import { ProductRoutes } from '../../routes/products';
import { HomepageRoutes } from '../../routes/homepage';
import { useModal } from '../../contexts/ModalContext';
import { ProductCategoryRoutes } from '../../routes/productCategories';
import { MemberRoutes } from '../../routes/members';
import { SettingRoutes } from '../../routes/settings';
import { ParticipantRoutes } from '../../routes/participants';
import { ResellerRoutes } from '../../routes/resellers';
import { PrizeRoutes } from '../../routes/prizes';
import { EmailTemplateRoutes } from '../../routes/templates';
import { SubscriberRoutes } from '../../routes/subscribers';
import { StoreRoutes } from '../../routes/stores';
import { LandingPagesRoutes } from '../../routes/landingpages';
import { OfficialProductCategoryRoutes } from '../../routes/officialProductCategory';
import { OfficialProductRoutes } from '../../routes/officalProduct';
import { BannersRoutes } from '../../routes/banners';
import { PromoCodeRoutes } from '../../routes/promoCodes';

export const Main: React.FC = () => {
  const { modal: { component: ModalComponent, props: modalProps }, hideModal } = useModal();

  return (
    <>
      {ModalComponent ? <ModalComponent {...modalProps} onRequestClose={hideModal} /> : null}
      <NavBar />
      <Sidebar.Pushable id="main">
        <SideBar />
        <Sidebar.Pusher id='main-content'>
          <LandingPagesRoutes />
          <HomepageRoutes />
          <CampaignRoutes />
          <PrizeRoutes />
          <PromoCodeRoutes />
          {/* <MemberRoutes /> */}
          <ParticipantRoutes />
          <ProductCategoryRoutes />
          <ProductRoutes />
          <StoreRoutes />
          <ResellerRoutes />
          <EmailTemplateRoutes />
          <SubscriberRoutes />
          <SettingRoutes />
          <OfficialProductCategoryRoutes />
          <OfficialProductRoutes />
          <BannersRoutes />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
}