import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, DropdownProps, Grid, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { OfficialProduct, KeyValue } from './OfficialProduct.type';
import { UploadImage, UploadImageWithFile } from '../../../types/fileTypes';
import { HandleInputChange } from '../../../types/inputs';
import { Images } from '../../Input/Images';
import axios from 'axios';
import { AxiosResponseError } from '../../../types/axios';

interface FormProps extends Omit<OfficialProduct, 'id' | 'image'> {
  image: UploadImageWithFile | null;
}

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const NewOfficialProduct: React.FC = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const [officialProductCategory, setOfficialProductCategory] = useState<KeyValue[]>();
  const [loading, setLoading] = useState(false);
  const { callApi } = useApi();

  const handleChange: HandleInputChange
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const handleFileChange = (name: string, file: UploadImage, files: FileList) => {
    setFormData({ ...formData, [name]: { ...file, files } });
  }
  const handleFileRemove = (name: string) => {
    setFormData({ ...formData, [name]: null });
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};
    if (!formData.officialProductCategoryId) {
      errors.image = "Official Product Category is required ";
      isValid = false;
    }
    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (!formData.image) {
      errors.image = "Image is required";
      isValid = false;
    }

    if (!formData.description) {
      errors.description = "Description is required";
      isValid = false;
    }

    if (!formData.url) {
      errors.url = "Url is required";
      isValid = false;
    }


    if (formData.priority && !/[0-9]/.test(formData.priority + " ")) {
      errors.priority = "Priority required integer only";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        setLoading(true);
        const params = new FormData();
        const img = formData.image?.files[0]
        //@ts-ignore
        params.append('image', img)
        params.append('name', formData.name)
        params.append('description', formData.description)
        params.append('url', formData.url)
        params.append('priority', formData.priority ? formData.priority : '0')
        params.append('officialProductCategoryId', formData.officialProductCategoryId)
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          }
        }
        await callApi.post('/officialProduct', params, config);
        setLoading(false);
        history.push('/officialProduct');
      }
      catch (e) {
        setLoading(false);
        setError(e);
      }
    }
  }

  const handleChangeDropDown: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void = (e, { name, value }) =>
      formData && setFormData({ ...formData, [name]: value });

  useEffect(() => {
    callApi
      .get<KeyValue[]>("/officialProductCategory/allKeyVal")
      .then(({ data }) => {
        console.log(data);
        setOfficialProductCategory(data);
      })
      .catch(e => {
        if (e.response.status !== 404) {
          console.log(e.message);
          setError(e);
        }
      });
  }, [callApi]);

  return (
    <div>
      <h1>Create New Official Product</h1>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input name='name' label="Name" width="8" onChange={handleChange}></Form.Input>
            <Form.Input name='description' label="Description" width="8" onChange={handleChange}></Form.Input>
            <Form.Input name='url' label="Url" width="8" onChange={handleChange}></Form.Input>
            <Grid style={{ marginBottom: "1em" }}>
              <Grid.Column>
                <label>Image</label>
                <Images name='image' onSuccess={handleFileChange} onRemove={handleFileRemove} />
              </Grid.Column>
            </Grid>
            <Form.Dropdown
              width="8"
              label="Official Product Category"
              placeholder="Official Product Category"
              name="officialProductCategoryId"
              value={formData.officialProductCategoryId}
              key={officialProductCategory?.length + "reseller"}
              onChange={handleChangeDropDown}
              fluid
              // multiple
              // search
              selection
              options={officialProductCategory}
            />
          </Form.Field>
          <Form.Input name='priority' label="Priority" width="8" onChange={handleChange}></Form.Input>
          <Form.Button color='vk' onClick={onSubmit} loading={loading}>Submit</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}