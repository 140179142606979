import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { InputOnChangeData, Form, Dropdown, DropdownItemProps, Message, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { ProductCategory, Product } from './product.type';
import _ from 'lodash';
import { AxiosResponseError } from '../../../types/axios';

interface Props extends RouteComponentProps<{ id: string }> { }

type FormProps = Omit<Product, 'id'>;

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const EditProduct: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const [formData, setFormData] = useState({} as FormProps);
  const [categoryOptions, setCategoryOptions] = useState([] as DropdownItemProps[]);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const { callApi } = useApi();
  const history = useHistory();

  useEffect(() => {
    callApi.get<Product>(`/product/${id}`)
      .then(({ data }) => setFormData(data))
      .then(() => callApi.get<ProductCategory[]>('/productCategory')
        .then(({ data: cats }) =>
          setCategoryOptions(cats.map<DropdownItemProps>(cat => ({
            key: cat.id,
            value: cat.id,
            text: cat.name
          })))))

  }, [callApi]);

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (!formData.productCategoryId) {
      errors.productCategoryId = "Category is required";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        await callApi.put(`/product/${id}`, _.pick(formData, ['name', 'productCategoryId']));
        history.push('/product');
      } catch (e) {
        setError(e);
      }
    }
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  return (
    <div>
      <h1>Edit Model</h1>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input
              name='name' label="Name" width="8"
              onChange={handleChange}
              value={formData.name}
            />
            <label>Product type</label>
            <Form.Dropdown selection
              width="8"
              options={categoryOptions}
              onChange={(e, selection) => selection.value && setFormData({ ...formData, productCategoryId: selection.value as number })}
              value={formData.productCategoryId}
            />
          </Form.Field>
          <Form.Button color='vk' onClick={onSubmit}>Update</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}