import React, { useState } from "react";
import { Form, Header, Message, Segment } from "semantic-ui-react";
import { useApi } from "../../contexts/ApiContext";
import { useAuth } from "../../contexts/AuthContext";
import { useUser } from "../../contexts/UserContext";
import { AxiosResponseError } from "../../types/axios";

type LoginApiResponse = {
  accessToken: string;
  refreshToken: string;
};

type FormErrors = {
  username?: string;
  password?: string;
};

export const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const { loginHandler } = useAuth();
  const { setUser } = useUser();
  const { callApi } = useApi();

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || "Some thing went wrong"}</p>
        </Message>
      </>
    );
  };

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!username) {
      errors.username = "Username is required";
      isValid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  };

  const onSubmit = async () => {
    if (formValidate()) {
      await callApi
        .post<LoginApiResponse>("/admin/login", { username, password })
        .then(({ data }) => {
          setUser({ name: username });
          loginHandler(data.accessToken, data.refreshToken);
        })
        .catch(e => setError(e));
    }
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        textAlign: "center",
        margin: "0 auto",
        position: "relative",
        top: "200px",
      }}
    >
      <Header as="h1" textAlign="center">
        Logitech MX
      </Header>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input
              icon="user"
              iconPosition='left'
              name="username"
              placeholder="Username"
              onChange={e => setUsername(e.target.value)}
              value={username}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              icon="lock"
              iconPosition='left'
              type="password"
              name="password"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </Form.Field>
          <Form.Button type="submit" color="vk" onClick={onSubmit}>
            Login
          </Form.Button>
        </Form>
      </Segment>
    </div>
  );
};
