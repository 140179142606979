import React from 'react';
import { useApi } from '../../../contexts/ApiContext';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

type Props = {
  id: number;
  onRequestClose: () => void;
}

export const DeleteSubscriber: React.FC<Props> = ({ id, onRequestClose }) => {
  const { callApi } = useApi();
  const history = useHistory();

  return (
    <Modal open onClose={onRequestClose} size="mini" >
      <Modal.Header>Delete subscriber</Modal.Header>
      <Modal.Content>{`Are you sure to delete this email from EDM`}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => callApi.delete(`/subscriber/${id}`).then(() => {
            onRequestClose();
            history.go(0);
          })}>Delete</Button>
        <Button onClick={onRequestClose}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
}