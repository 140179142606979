import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { InputOnChangeData, Form, Message, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { ProductCategory } from './product.type';
import _ from 'lodash';
import { AxiosResponseError } from '../../../types/axios';

interface Props extends RouteComponentProps<{ id: string }> { }

type FormProps = Omit<ProductCategory, 'id'>;

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const EditProductCategory: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const [formData, setFormData] = useState({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const { callApi } = useApi();
  const history = useHistory();

  useEffect(() => {
    callApi.get<ProductCategory>(`/productCategory/${id}`).then(({ data }) => {
      setFormData(data);
    })
  }, [callApi]);

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>, formData: InputOnChangeData) => void
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        await callApi.put(`/productCategory/${id}`, _.pick(formData, ['name']));
        history.push('/productCategory');
      } catch (e) {
        setError(e);
      }
    }
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors] || 'Some thing went wrong'}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  return (
    <div>
      <h1>Edit Product</h1>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input
              name='name' label="Name" width="8"
              onChange={handleChange}
              value={formData.name} />
          </Form.Field>
          <Form.Button color='vk' onClick={onSubmit}>Update</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}