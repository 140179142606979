import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Grid, Table, Image, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { useModal } from '../../../contexts/ModalContext';
import { ActionButtons } from '../../Button/ActionButtons';
import { DeletePrize } from './DeletePrize';
import { Prize } from './prize.type';

export const ListPrizes: React.FC = () => {
  const [prizes, setPrizes] = useState([] as Prize[]);
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  useEffect(() => {
    callApi.get<Prize[]>('/prize')
      .then(({ data }) => {
        setPrizes(data);
      })
  }, [callApi])

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Prize</h1>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${url}/new`}>
            <Button color='green'>New prize</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Segment><Table sortable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Worth</Table.HeaderCell>
            <Table.HeaderCell>Image</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {prizes.map(prize => (
            <Table.Row key={prize.id}>
              <Table.Cell>{prize.id}</Table.Cell>
              <Table.Cell>{prize.name}</Table.Cell>
              <Table.Cell>{prize.worth}</Table.Cell>
              <Table.Cell><Image src={prize.image} size="small" /></Table.Cell>
              <Table.Cell><ActionButtons actions={['edit', 'delete']} elementId={prize.id} onDeleteClick={() => { showModal(DeletePrize, { id: prize.id, name: prize.name }) }} /></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table></Segment>
    </>
  );
}