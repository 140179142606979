import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { Store } from './store.type';
import { UploadImage } from '../../../types/fileTypes';
import { HandleInputChange } from '../../../types/inputs';
import { Images } from '../../Input/Images';
import axios from 'axios';
import _ from 'lodash';
import { AxiosResponseError } from '../../../types/axios';

interface Props extends RouteComponentProps<{ id: string }> { }

interface FormProps extends Omit<Store, 'id' | 'image'> {
  image: UploadImage | null | undefined;
}

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const EditStore: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const history = useHistory();
  const [store, setStore] = useState({} as Store);
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const [loading, setLoading] = useState(false);
  const { callApi } = useApi();

  useEffect(() => {
    callApi.get<Store>(`/store/${id}`)
      .then(({ data }) => {
        setFormData({ ...data, image: undefined })
        setStore(data);
      })
  }, [callApi])

  const handleChange: HandleInputChange
    = (e, { name, value }) => { console.log(e); setFormData({ ...formData, [name]: value }) }

  const handleFileChange = (name: string, file: UploadImage) => {
    setFormData({ ...formData, [name]: file });
  }

  const handleFileRemove = (name: string) => {
    setFormData({ ...formData, [name]: null });
  }

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (formData.image === null) {
      errors.image = "Image is required";
      isValid = false;
    }

    if (!formData.url) {
      errors.url = "URL is required";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        setLoading(true);
        let imageUrl = '';

        if (formData.image) {
          const { data: signedUrl } = await callApi.get<string>(`/s3/uploadUrl?folder=store&fileExtension=${formData.image.type.split('/')[1]}`);
          await axios.put(signedUrl, formData.image.data, {
            headers: {
              'Content-Type': formData.image.type
            }
          });

          imageUrl = signedUrl.split('?')[0];
        }

        await callApi.put(`/store/${id}`, {
          ..._.pick(formData, ['name', 'url']),
          image: formData.image === undefined ? store.image : imageUrl
        });

        history.push('/store');
      }
      catch (e) {
        setLoading(false);
        setError(e);
      }
    }
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  return (
    <div>
      <h1>Edit Store</h1>
      {renderError()}
      <Form key={store.id}>
        <Form.Field>
          <Form.Input
            name='name' label="Name" width="8"
            value={formData.name}
            onChange={handleChange}
          />
          <Form.Input
            name='url' label="URL" width="8"
            value={formData.url}
            onChange={handleChange}
          />
          <label>Image</label>
          <Images key={store.id} name='image' onSuccess={handleFileChange} src={store.image} onRemove={handleFileRemove} />
        </Form.Field>
        <Form.Button color='vk' onClick={onSubmit} loading={loading}>Submit</Form.Button>
      </Form>
    </div>
  );
}