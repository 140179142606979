import React from 'react';
import { Route } from 'react-router-dom'
import { ListProductCategories } from '../components/Main/Product/ListCategories';
import { NewProductCategory } from '../components/Main/Product/NewCategory';
import { EditProductCategory } from '../components/Main/Product/EditCategory';

export const ProductCategoryRoutes = () => {
  return (
    <>
      <Route path='/productCategory' exact component={ListProductCategories} />
      <Route path='/productCategory/new' component={NewProductCategory} />
      <Route path='/productCategory/edit/:id' component={EditProductCategory} />
    </>
  )
}