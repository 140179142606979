import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  InputOnChangeData,
  Message,
  Image,
  Dropdown,
  DropdownProps,
  Segment,
  FormGroup,
  List,
  Header,
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { Banners } from "./Banner.type";
import moment from "moment";
import _, { String, values } from "lodash";
import { Link, useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosResponseError } from "../../../types/axios";
import { Images } from "../../Input/Images";
import { UploadImage } from "../../../types/fileTypes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
  DeepMap,
  FieldError,
  useController,
} from "react-hook-form";
import ReactQuill from "react-quill";
import test from "react-quill";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RichEditorController } from "../../Input/RichEditorController";

export interface FormProps extends Omit<Banners, "id"> {}
interface Props extends RouteComponentProps<{ id: string }> {}

type FormErrors = {
  [key in keyof FormProps]?: string;
};

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
    desc: yup.string().required("Description is required"),
    buttonText: yup.string().required("Button text is required"),
    redirectTo: yup.string().required("Redirect url is required"),
    desktopImage: yup
      .mixed()
      .test("testnull", "Image is required", function (v) {
        if (v === null) {
          return false;
        }
        return true;
      }),
  })
  .required();

export const BannersForm: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const { callApi } = useApi();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosResponseError | null>(null);
  const [bannerOri, setBannerOri] = useState<{ desktopImage: string }>();
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    control,
  } = useForm<Banners>({
    resolver: yupResolver(schema),
  });

  const { field: desktopImageField } = useController({
    name: "desktopImage",
    control,
    defaultValue: id ? undefined : null,
  });

  useEffect(() => {
    formValidate();
  }, [errors]);

  type ErrorsType = DeepMap<Banners, FieldError> & {
    [index: string]: any;
  };

  useEffect(() => {
    async function getBanner() {
      try {
        const { data } = await callApi.get<Banners>("/banners/" + id);
        const { desktopImage, ...dt } = data;
        setBannerOri({ desktopImage: desktopImage as unknown as string });
        console.log("set", dt);
        reset(dt);
      } catch (e) {
        if (e.response.status !== 404) {
          console.log(e.message);
        }
      }
    }
    if (id) {
      getBanner();
    }
  }, [callApi, id]);

  const formValidate = (): boolean => {
    if (loading === true) {
      return false;
    }
    let isValid = true;
    const formErrors: FormErrors = {};
    const parsedErrors = errors as ErrorsType;
    Object.keys(errors).forEach((k) => {
      const val = parsedErrors[k];
      formErrors[k] = val.message;
    });

    setFormErrors({ ...formErrors });
    return isValid;
  };

  const onSubmit = async () => {
    try {
      if (loading === true) {
        return;
      }

      setLoading(true);
      const data = getValues();
      const { ...formData } = data;
      const params = new FormData();
      if (formData.desktopImage) {
        const img = formData.desktopImage[0];
        //@ts-ignore
        params.append("desktopImage", img);
      }
      params.append("title", formData.title);
      params.append("desc", formData.desc);
      params.append("buttonText", formData.buttonText);
      params.append("redirectTo", formData.redirectTo);
      params.append("name", formData.name);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      if (id) {
        await callApi.put(`/banners/${id}`, params, config);
      } else {
        await callApi.post(`/banners`, params, config);
      }
      setLoading(false);
      history.push("/banners");
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };
  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(formErrors).length} negative>
          <p>{formErrors[Object.keys(formErrors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || "Some thing went wrong"}</p>
        </Message>
      </>
    );
  };
  return (
    <>
      <Grid columns={16} style={{ marginBottom: "10px" }}>
        <Grid.Column width={13}>
          <h1>{id ? "Edit" : "New"} Banners</h1>
        </Grid.Column>
      </Grid>
      <Segment>
        {renderError()}
        <Form>
          <Form.Group>
            <Controller
              control={control}
              name="name"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Form.Input label="Name" width="8" {...field} ref={undefined} />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Controller
              control={control}
              name="buttonText"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Form.Input
                  label="Button Text"
                  width="8"
                  {...field}
                  ref={undefined}
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Controller
              control={control}
              name="redirectTo"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Form.Input
                  label="Redirect Url"
                  width="8"
                  {...field}
                  ref={undefined}
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>
                <b>Image</b>
              </label>
              <Images
                name={desktopImageField.name}
                key={bannerOri?.desktopImage}
                src={bannerOri?.desktopImage}
                onSuccess={(
                  name: string,
                  file: UploadImage,
                  files: FileList
                ) => {
                  setValue("desktopImage", null);
                  setValue("desktopImage", files);
                }}
                onRemove={() => {
                  setValue("desktopImage", null);
                }}
              />
              <Message info>
                <Header size="tiny">Banner Size :</Header>
                <List bulleted>
                  <List.Item>Rewards : 850x400</List.Item>
                </List>
              </Message>
            </Form.Field>
          </Form.Group>

          <FormGroup style={{ marginTop: 30 }}>
            <Form.Field>
              <label>
                <b>Title</b>
              </label>
              <RichEditorController name="title" control={control} />
              <Message info>
                <Header size="tiny">Recommended Font Size :</Header>
                <List bulleted>
                  <List.Item>Rewards : Heading 3</List.Item>
                </List>
              </Message>
            </Form.Field>
            <Form.Field>
              <label>
                <b>Description</b>
              </label>

              <RichEditorController name="desc" control={control} />
              <Message info>
                <Header size="tiny">Recommended Font Size :</Header>
                <List bulleted>
                  <List.Item>Rewards : Heading 4</List.Item>
                </List>
              </Message>
            </Form.Field>
          </FormGroup>
          <FormGroup style={{ marginTop: 30 }}></FormGroup>

          <Form.Button
            style={{ marginTop: "50px" }}
            color="vk"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            loading={loading}
          >
            Save
          </Form.Button>
        </Form>
      </Segment>
    </>
  );
};

function QuillFieldControllerWrap({ control, name }) {
  const { field } = useController({
    name,
    control,
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  return (
    <ReactQuill
      onChange={field.onChange}
      value={field.value ?? ""}
      modules={modules}
    />
  );
}
