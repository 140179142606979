import React, { useState } from 'react';
import { ControllerRenderProps, useController, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import {
    Button,
    Form,
    Grid,
    InputOnChangeData,
    Message,
    Image,
    Dropdown,
    DropdownProps,
    Segment,
    FormGroup,
    Header,
    Divider,
    Accordion,
    DropdownItemProps,
    SemanticWIDTHS,
    Icon,
} from "semantic-ui-react";

interface DDropdown<T> {
    formContext: UseFormReturn<T, object>;
    name: any;
    option: DropdownItemProps[];
    label: string;
}


export default function DDropdown<T>({ name, formContext, option, label }: DDropdown<T>) {
    const { control, setValue, getValues, setError, clearErrors, formState: { errors }, } = formContext;
    const [addingEmail, setAddingEmail] = useState('');

    const {
        field,
    } = useController({
        name,
        control,
    });

    const setAll = () => {
        const item = []
        for (const key in option) {
            const element = option[key];
            item.push(element.value)

        }
        setValue(name, item as any)
    }

    const removeAll = () => {
        setValue(name, [] as any)
    }


    return (
        <div style={
            { width: "100%", display: 'flex', flexDirection: "column" , marginBottom: "1em" }
        }>
            <Form.Dropdown
            mb={0}
                label={label}
                width={16}
                {...field}
                value={field.value as any || []}
                onChange={(e, { name, value }) => {
                    setValue(name, value as any)
                }}
                ref={undefined}
                fluid
                multiple
                search
                selection
                options={option || []}
            />
            <div style={{ paddingRight: '0.5em',  display: 'flex', marginTop: "-0.5em"}}>
                <Button icon style={{ maxHeight: '38px', height: '38px', alignSelf: 'end' }}
                    onClick={setAll}>
                        Select All
                </Button>
                <Button icon style={{ maxHeight: '38px', height: '38px', alignSelf: 'end' }}
                    onClick={removeAll}>
                        Deselect All
                </Button>
            </div>
        </div>
    );
}
