import React from 'react';
import { Button } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { Headers } from 'react-csv/components/CommonPropTypes';

type Props = {
  data: string | object[];
  headers: Headers;
  filename: string | undefined;
}

export const csvLinkRef = React.createRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>();

export const ExportCSVButton: React.FC<Props> = ({ data, headers, filename }) => {
  return (
    <Button size="medium" basic color="teal">
      <CSVLink data={data} headers={headers} filename={filename}>Export</CSVLink>
    </Button>
  );
}