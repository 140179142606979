import React from 'react';
import { Route } from 'react-router-dom'
import { ListParticipants } from '../components/Main/Participant/ListParticipants';
import { TTab } from '../components/Main/Participant/Tab';

export const ParticipantRoutes = () => {
  return (
    <>
      <Route path='/landingpages/:id/submission' exact component={ListParticipants} />
      <Route path='/landingpages/submission' exact component={TTab} />
    </>
  )
}