import React from 'react';
import { Route } from 'react-router-dom'
import { ListOfficialProduct } from '../components/Main/OfficialProduct/ListOfficialProduct';
import { NewOfficialProduct } from '../components/Main/OfficialProduct/NewOfficialProduct';
import { EditOfficialProduct } from '../components/Main/OfficialProduct/EditOfficialProduct';

export const OfficialProductRoutes = () => {
    return (
        <>
            <Route path='/officialProduct' exact component={ListOfficialProduct} />
            <Route path='/officialProduct/new' component={NewOfficialProduct} />
            <Route path='/officialProduct/edit/:id' component={EditOfficialProduct} />
        </>
    )
}