import React from 'react';
import { Route } from 'react-router-dom'
import { EditReseller } from '../components/Main/Reseller/EditReseller';
import { ListResellers } from '../components/Main/Reseller/ListResellers';
import { NewReseller } from '../components/Main/Reseller/NewReseller';

export const ResellerRoutes = () => {
  return (
    <>
      <Route path='/reseller' exact component={ListResellers} />
      <Route path='/reseller/new' component={NewReseller} />
      <Route path='/reseller/edit/:id' component={EditReseller} />
    </>
  )
}