import React, { useState } from 'react';
import { Button, Input, Modal } from 'semantic-ui-react';

type Props = {
  participantId: number;
  onRequestClose: () => void;
  onReject: (participantId: number, reason: string) => void;
}

export const RejectParticipant: React.FC<Props> = ({ participantId, onReject, onRequestClose }) => {
  const [reason, setReason] = useState('');

  return (
    <Modal open onClose={onRequestClose} size="small" >
      <Modal.Header>Reject participant</Modal.Header>
      <Modal.Content>
        Please specify the reason of rejection
        <Input placeholder="Reason" inline value={reason} onChange={e => setReason(e.target.value)} fluid />
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            onReject(participantId, reason);
            onRequestClose();
          }}>
          Proceed
        </Button>
        <Button onClick={onRequestClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}