import React from 'react';
import { useApi } from '../../../contexts/ApiContext';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

type Props = {
  onRequestClose: () => void;
  campaignId: string | number | boolean | undefined;
  landingId: string;
}

export const ClearAllParticipants: React.FC<Props> = ({ onRequestClose, campaignId, landingId }) => {
  const { callApi } = useApi();
  const history = useHistory();
  return (
    <Modal open onClose={onRequestClose} size="small" >
      <Modal.Header>Clear all participants</Modal.Header>
      <Modal.Content>
        Are you sure
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            callApi.delete('/participant', { params: { campaignId, landingId } }).then(() => {
              onRequestClose();
              history.go(0);
            })
          }}>
          Proceed
        </Button>
        <Button onClick={onRequestClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}