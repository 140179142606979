import React, { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownProps,
    Grid, Pagination, Search, SearchProps, Segment, Table, Loader, Dimmer
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { Link, useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosResponseError } from "../../../types/axios";
import _ from "lodash";

interface Props extends RouteComponentProps<{ id?: string }> {
    landingId: number
}


const showDropdownOptions = [
    {
        key: 1,
        text: "10",
        value: 10,
    },
    {
        key: 2,
        text: "20",
        value: 20,
    },
    {
        key: 3,
        text: "50",
        value: 50,
    },
    {
        key: 4,
        text: "100",
        value: 100,
    },
];

const mapRawToViewData = (data) => {
    return data.map(entry => ({
        email: entry.email,
        first_name: entry.first_name,
        last_name: entry.last_name,
        campaign: entry.custom_fields.campaign,
        edm: entry.custom_fields.edm,
    }));
};

export const ContactList: React.FC<Props> = ({ match, landingId }) => {
    const { id: type } = match.params;
    const { callApi } = useApi();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [contactLists, setContactLists] = useState([]);
    const [oriLists, setOriLists] = useState([]);
    const [searching, setSearching] = useState<string>();
    const [edm, setEdm] = useState<string>(undefined);
    const [error, setError] = useState<AxiosResponseError | null>(null);
    const [pagination, setPagination] = useState({
        numShow: showDropdownOptions[0].value,
        currentPage: 1,
    });
    const { numShow, currentPage } = pagination;

    var name = '';
    var id = 0;
    switch (type) {
        case 'mx':
            name = 'MX'
            id = 1
            break;
        default:
            break;
    }
    useEffect(() => {
        setLoading(true)
        callApi.get(`/landing/contactList/${id ? id : landingId}`).then(({ data }) => {
            const { success, data: contactListsReturn } = data

            if (success === false) {
                throw data.message
            }
            if (contactListsReturn.contact_sample) {
                setOriLists(mapRawToViewData(contactListsReturn.contact_sample))
            } else {
                setOriLists([])
            }
            setLoading(false)
        }).catch(e => {
            console.log(e)
            if (e.response.status !== 404) {
                setError(e);
            }
        });
    }, [callApi, id, landingId]);

    useEffect(() => {
        var filter
        if (searching) {
            filter = oriLists.filter((object) => {
                for (const key in object) {
                    if (key == 'edm') {
                        continue
                    }
                    const element = object[key].toLowerCase();
                    if (element.includes(searching.toLowerCase())) {
                        return object
                    }
                }
            })
        } else {
            filter = oriLists
        }

        if (edm != undefined) {
            filter = oriLists.filter(x => {
                if (edm == "1") {
                    if (x.edm == 1) {
                        return x
                    }
                } else {
                    if (x.edm != 1) {
                        return x
                    }
                }
            })
        }

        const startIndex = currentPage * numShow - numShow;
        const endIndex = startIndex + numShow;
        const item = filter.slice(startIndex, endIndex);
        setContactLists(item)
    }, [currentPage, numShow, oriLists.length, searching, edm]);


    const onSearching = async (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        data: SearchProps
    ) => {
        setSearching(data?.value);
    };
    return (
        <>
            <Grid columns={16} style={{ marginBottom: "10px" }}>
                <Grid.Column width={13}>
                    <h1>{name} Contact Lists</h1>
                </Grid.Column>
            </Grid>
            <Segment>
                <Grid columns={2} stackable >
                    <Grid.Column >
                        <Search
                            className="search-bar"
                            onSearchChange={_.debounce((onSearching), 500)}
                            showNoResults={false}
                            size="mini"
                        />
                        <Dropdown text="EDM" icon="filter" labeled button className="icon">
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    text="All"
                                    value={undefined}
                                    active={!edm}
                                    onClick={(e, data) => {
                                        setEdm(undefined)
                                    }}
                                />
                                <Dropdown.Item
                                    text="Yes"
                                    value="1"
                                    active={edm == "1"}
                                    onClick={(e, data) => {
                                        setEdm(data.value as string)
                                    }}
                                />
                                <Dropdown.Item
                                    text="No"
                                    value="2"
                                    active={edm == "2"}
                                    onClick={(e, data) => {
                                        setEdm(data.value as string)
                                    }}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid.Column>
                    <Grid.Column textAlign="right" >
                        <Dropdown
                            text={`Show: ${numShow}`}
                            button
                            selection
                            compact
                            options={showDropdownOptions}
                            defaultValue={showDropdownOptions[0].value}
                            onChange={(_e, data) => {
                                setPagination({ currentPage: 1, numShow: data?.value as number })
                            }}
                        />
                        <Pagination
                            disabled={oriLists.length == 0}
                            className="pagination-bar"
                            totalPages={Math.ceil(oriLists.length / numShow)}
                            siblingRange={1}
                            boundaryRange={0}
                            ellipsisItem={null}
                            onPageChange={(_e, data) =>
                                setPagination({ currentPage: data.activePage as number, numShow })
                            }
                            activePage={currentPage}
                        />
                    </Grid.Column>
                </Grid>

                <Table sortable striped columns={5}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Campaign
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                EDM
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {loading}
                        {(loading === true) ? (
                            <Dimmer active inverted>
                                <Loader>Loading</Loader>
                            </Dimmer>
                        ) : ""}
                        {(contactLists.length == 0 && loading === false) ?
                            (
                                <React.Fragment >
                                    <Table.Row>
                                        <Table.Cell colSpan="7" textAlign="center">
                                            No Record
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            ) : ""}
                        {contactLists.map((participant, key) => {
                            return (
                                <React.Fragment key={key + 1 + (currentPage - 1) * numShow}>
                                    <Table.Row >
                                        <Table.Cell>
                                            {" "}
                                            #{key + 1 + (currentPage - 1) * numShow}
                                        </Table.Cell>
                                        <Table.Cell> {participant.email}</Table.Cell>
                                        <Table.Cell width={2}>{participant.first_name + ' ' + participant.last_name}</Table.Cell>
                                        <Table.Cell width={2}>{participant.campaign}</Table.Cell>
                                        <Table.Cell width={2}>{participant.edm ? 'Yes' : 'No'}</Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        </>
    );
};
