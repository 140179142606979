import React from 'react';
import { Route } from 'react-router-dom'
import { EditStore } from '../components/Main/Store/EditStore';
import { ListStores } from '../components/Main/Store/ListStores';
import { NewStore } from '../components/Main/Store/NewStore';

export const StoreRoutes = () => {
  return (
    <>
      <Route path='/Store' exact component={ListStores} />
      <Route path='/Store/new' component={NewStore} />
      <Route path='/Store/edit/:id' component={EditStore} />
    </>
  )
}