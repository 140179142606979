import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Form, Message, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { OfficialProductCategory } from './OfficialProductCategory.type';
import { UploadImage } from '../../../types/fileTypes';
import { HandleInputChange } from '../../../types/inputs';
import { Images } from '../../Input/Images';
import axios from 'axios';
import _ from 'lodash';
import { AxiosResponseError } from '../../../types/axios';

interface Props extends RouteComponentProps<{ id: string }> { }

interface FormProps extends Omit<OfficialProductCategory, 'id' | 'image'> {
}

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const EditOfficialProductCategory: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const history = useHistory();
  const [officialProductCategory, setOfficialProductCategory] = useState({} as OfficialProductCategory);
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const [loading, setLoading] = useState(false);
  const { callApi } = useApi();

  useEffect(() => {
    callApi.get<OfficialProductCategory>(`/officialProductCategory/${id}`)
      .then(({ data }) => {
        setFormData({ ...data })
        setOfficialProductCategory(data);
      })
  }, [callApi])

  const handleChange: HandleInputChange
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const handleFileChange = (name: string, file: UploadImage) => {
    setFormData({ ...formData, [name]: file });
  }

  const handleFileRemove = (name: string) => {
    setFormData({ ...formData, [name]: null });
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (!formData.description) {
      errors.description = "Desription is required";
      isValid = false;
    }

    if (!formData.btnText) {
      errors.btnText = "Button Text must be a number";
      isValid = false;
    }

    if (!formData.redirectUrl) {
      errors.redirectUrl = "Redirection url is required";
      isValid = false;
    }
    if (formData.priority && !/[0-9]/.test(formData.priority+" ")){
      errors.priority = "Priority required integer only";
      isValid = false;
    }
    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        setLoading(true);

        if(!formData.priority){
          formData.priority = '0'
        }
        await callApi.put(`/officialProductCategory/${id}`, {
          ..._.pick(formData, ['name', 'description', 'btnText', 'redirectUrl','priority']),
        });

        history.push('/officialProductCategory');
      }
      catch (e) {
        setLoading(false);
        setError(e);
      }
    }
  }

  return (
    <div>
      <h1>Edit Official Product Category</h1>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input name='name' label="Name" width="8" value={formData.name} onChange={handleChange}></Form.Input>
            <Form.Input name='description' label="Description" width="8" value={formData.description} onChange={handleChange}></Form.Input>
            <Form.Input name='btnText' label="Button Text" width="8" value={formData.btnText} onChange={handleChange}></Form.Input>
            <Form.Input name='redirectUrl' label="Redirect Url" width="8" value={formData.redirectUrl} onChange={handleChange}></Form.Input>
            <Form.Input name='priority' label="Priority" width="8" value={formData.priority} onChange={handleChange}></Form.Input>
          </Form.Field>
          <Form.Button color='vk' onClick={onSubmit} loading={loading}>Submit</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}