import React from 'react';
import { Route } from 'react-router-dom'
import { Setting } from '../components/Main/Setting/Setting';

export const SettingRoutes = () => {
  return (
    <>
      {/* <Route path='/setting' exact component={Setting} /> */}
    </>
  )
}