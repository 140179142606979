import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItemProps, Form, Message, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { Product, ProductCategory } from './product.type';
import { UploadImage } from '../../../types/fileTypes';
import { HandleInputChange } from '../../../types/inputs';
import { AxiosResponseError } from '../../../types/axios';

interface FormProps extends Omit<Product, 'id' | 'image'> {
  image: UploadImage;
}

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const NewProduct: React.FC = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const [categoryOptions, setCategoryOptions] = useState<DropdownItemProps[]>([]);
  const { callApi } = useApi();

  useEffect(() => {
    callApi.get<ProductCategory[]>('/productCategory')
      .then(({ data: cats }) => {
        setCategoryOptions(cats.map<DropdownItemProps>(cat => ({
          key: cat.id,
          value: cat.id,
          text: cat.name
        })));

        setFormData({ ...formData, productCategoryId: cats[0].id })
      })

  }, [callApi])

  const handleChange: HandleInputChange
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (!formData.productCategoryId) {
      errors.productCategoryId = "Category is required";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    try {
      if (formValidate()) {
        await callApi.post('/product', formData)
        history.push('/product');
      }
    } catch (e) {
      setError(e);
    }
  }

  return (
    <div>
      <h1>Create New Model</h1>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input name='name' label="Name" width="8" onChange={handleChange}></Form.Input>
            <label>Category</label>
            <Form.Dropdown
              key={categoryOptions.length}
              options={categoryOptions}
              value={formData.productCategoryId}
              selection
              width="8"
              onChange={(e, selection) => selection.value && setFormData({ ...formData, productCategoryId: selection.value as number })}
            />
          </Form.Field>
          <Form.Button color='vk' onClick={onSubmit}>Submit</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}