import React from "react";
import { Modal, Image } from "semantic-ui-react";

type ImageModalProps = {
  src: string;
  onRequestClose: () => void;
}

export const ImageModal: React.FC<ImageModalProps> = ({ src, onRequestClose }) => {

  return (
    <Modal basic open onClose={onRequestClose} size="large" closeIcon>
      <Image size="huge" src={src} centered />
    </Modal>
  );
}