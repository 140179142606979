import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Button, Grid, Table, Image, Segment } from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { useModal } from "../../../contexts/ModalContext";
import { ActionButtons } from "../../Button/ActionButtons";
import { DeleteCampaign } from "./DeleteCampaign";
import { SegmentCampaign } from "./SegmentCampaign";
import { Campaign, LandingType } from "./campaign.type";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

interface Props extends RouteComponentProps<{ id?: string }> {
  landingId: number
}

export const ListCampaign: React.FC<Props> = ({ match, landingId }) => {
  const { id } = match.params;
  const [campaigns, setCampaign] = useState([] as Campaign[]);
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  const landingType: LandingType = { mx: "1" };

  useEffect(() => {
    callApi
      .get<Campaign[]>("/campaign", { params: { landingId: landingType[id] ? landingType[id] : landingId } })
      .then(({ data }) => {
        setCampaign(data);
      });
  }, [callApi, id]);

  return (
    <>
      <h1>Campaigns</h1>
      <Segment>
        <Link to={id ? `${url}/new` : `mix/${landingId}/campaign/new`}>
          <Button color="green">New campaign</Button>
        </Link>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell>EndDate</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {campaigns.map((campaign, key) => (
              <Table.Row key={campaign.id}>
                <Table.Cell>{"C" + campaign.id}</Table.Cell>
                <Table.Cell>{campaign.name}</Table.Cell>
                <Table.Cell>{moment(campaign.startDate).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')
                }</Table.Cell>
                <Table.Cell>{moment(campaign.endDate).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                <Table.Cell>
                  <ActionButtons
                    actions={["edit", "delete"]}
                    elementId={campaign.id}
                    onDeleteClick={() => {
                      showModal(DeleteCampaign, { id: campaign.id });
                    }}

                  // onActionClick={() => {
                  //   showModal(SegmentCampaign, { id: campaign.id });
                  // }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};
