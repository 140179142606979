import React from 'react';
import { Route } from 'react-router-dom'
import { ListOfficialProductCategory } from '../components/Main/OfficialProductCategory/ListOfficialProductCategory';
import { NewOfficialProductCategory } from '../components/Main/OfficialProductCategory/NewOfficialProductCategory';
import { EditOfficialProductCategory } from '../components/Main/OfficialProductCategory/EditOfficialProductCategory';

export const OfficialProductCategoryRoutes = () => {
  return (
    <>
      <Route path='/officialProductCategory' exact component={ListOfficialProductCategory} />
      <Route path='/officialProductCategory/new' component={NewOfficialProductCategory} />
      <Route path='/officialProductCategory/edit/:id' component={EditOfficialProductCategory} />
    </>
  )
}