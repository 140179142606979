import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Form, Message, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { Prize } from './prize.type';
import { UploadImage } from '../../../types/fileTypes';
import { HandleInputChange } from '../../../types/inputs';
import { Images } from '../../Input/Images';
import axios from 'axios';
import _ from 'lodash';
import { AxiosResponseError } from '../../../types/axios';

interface Props extends RouteComponentProps<{ id: string }> { }

interface FormProps extends Omit<Prize, 'id' | 'image'> {
  image: UploadImage | null | undefined;    // TODO: Change this messy shit
}

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const EditPrize: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const history = useHistory();
  const [prize, setPrize] = useState({} as Prize);
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const [loading, setLoading] = useState(false);
  const { callApi } = useApi();

  useEffect(() => {
    callApi.get<Prize>(`/prize/${id}`)
      .then(({ data }) => {
        setFormData({ ...data, image: undefined })
        setPrize(data);
      })
  }, [callApi])

  const handleChange: HandleInputChange
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const handleFileChange = (name: string, file: UploadImage) => {
    setFormData({ ...formData, [name]: file });
  }

  const handleFileRemove = (name: string) => {
    setFormData({ ...formData, [name]: null });
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (!formData.worth) {
      errors.worth = "Worth is required";
      isValid = false;
    }

    if (isNaN(formData.worth)) {
      errors.worth = "Worth must be a number";
      isValid = false;
    }

    if (formData.image === null) {
      errors.image = "Image is required";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        setLoading(true);

        let imageUrl = '';

        if (formData.image) {
          const { data: signedUrl } = await callApi.get<string>(`/s3/uploadUrl?folder=prize&fileExtension=${formData.image.type.split('/')[1]}`);
          await axios.put(signedUrl, formData.image.data, {
            headers: {
              'Content-Type': formData.image.type
            }
          });

          imageUrl = signedUrl.split('?')[0];
        }

        await callApi.put(`/prize/${id}`, {
          ..._.pick(formData, ['name', 'worth']),
          image: formData.image === undefined ? prize.image : imageUrl
        });

        history.push('/prize');
      }
      catch (e) {
        setLoading(false);
        setError(e);
      }
    }
  }

  return (
    <div>
      <h1>Edit Prize</h1>
      <Segment>
        {renderError()}
        <Form key={prize.id}>
          <Form.Field>
            <Form.Input name='name' label="Name" width="8" value={formData.name} onChange={handleChange}></Form.Input>
            <Form.Input name='worth' label="Worth" width="8" value={formData.worth} onChange={handleChange}></Form.Input>
            <label>Image</label>
            <Images key={prize.id} name='image' onSuccess={handleFileChange} src={prize.image} onRemove={handleFileRemove} />
          </Form.Field>
          <Form.Button color='vk' onClick={onSubmit} loading={loading}>Submit</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}