import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Grid, Table, Image, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { useModal } from '../../../contexts/ModalContext';
import { ActionButtons } from '../../Button/ActionButtons';
import { DeleteReseller } from './DeleteReseller';
import { Reseller } from './reseller.type';

export const ListResellers: React.FC = () => {
  const [resellers, setResellers] = useState([] as Reseller[]);
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  useEffect(() => {
    callApi.get<Reseller[]>('/reseller')
      .then(({ data }) => {
        setResellers(data);
      })
  }, [callApi])

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Resellers</h1>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${url}/new`}>
            <Button color='green'>New reseller</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Segment>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>URL</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Official</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {resellers.map((reseller, key) => (
              <Table.Row key={reseller.id}>
                <Table.Cell>{key + 1}</Table.Cell>
                <Table.Cell>{reseller.name}</Table.Cell>
                <Table.Cell>{reseller.url}</Table.Cell>
                <Table.Cell><Image src={reseller.image} size="small" /></Table.Cell>
                <Table.Cell>{reseller.type == 'retail' ? 'No' : 'Yes'}</Table.Cell>
                <Table.Cell><ActionButtons actions={['edit', 'delete']} elementId={reseller.id} onDeleteClick={() => { showModal(DeleteReseller, { id: reseller.id, name: reseller.name }) }} /></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
}