import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichEditor.css';

export interface Props {
  name: string;
  content: string | undefined;
  onChange: (name: string, content: string) => void;
}

export const RichEditor: React.FC<Props> = ({ content, onChange, name }) => {
  return (
    <div>
      <ReactQuill
        value={content}
        onChange={(content) => onChange(name, content)}
      />
    </div>
  );
}