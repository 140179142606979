import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Button, Grid, Message, Segment, Table } from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { useModal } from "../../../contexts/ModalContext";
import { ActionButtons } from "../../Button/ActionButtons";
import { DeleteProduct } from "./DeleteProduct";
import { Product } from "./product.type";

export const ListProducts: React.FC = () => {
  const [products, setProducts] = useState([] as Product[]);
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  useEffect(() => {
    callApi.get<Product[]>("/product").then(({ data }) => {
      setProducts(data);
    });
  }, [callApi]);

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Product</h1>
          <Message info size="small">
            Product that will appear in submission form
          </Message>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${url}/new`}>
            <Button color="green">New model</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Segment>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Product Type</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {products.map((product) => (
              <Table.Row key={product.id}>
                <Table.Cell>{product.id}</Table.Cell>
                <Table.Cell>{product.name}</Table.Cell>
                <Table.Cell>{product.ProductCategory?.name}</Table.Cell>
                <Table.Cell>
                  <ActionButtons
                    actions={["edit", "delete"]}
                    elementId={product.id}
                    onDeleteClick={() => {
                      showModal(DeleteProduct, {
                        id: product.id,
                        name: product.name,
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};
