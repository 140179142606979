import React from 'react';
import { Route } from 'react-router-dom'
import { ListPrizes } from '../components/Main/Prize/ListPrizes';
import { NewPrize } from '../components/Main/Prize/NewPrize';
import { EditPrize } from '../components/Main/Prize/EditPrize';

export const PrizeRoutes = () => {
  return (
    <>
      <Route path='/Prize' exact component={ListPrizes} />
      <Route path='/Prize/new' component={NewPrize} />
      <Route path='/Prize/edit/:id' component={EditPrize} />
    </>
  )
}