import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Grid, Table, Image } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { useModal } from '../../../contexts/ModalContext';
import { ActionButtons } from '../../Button/ActionButtons';
import { DeleteStore } from './DeleteStore';
import { Store } from './store.type';

export const ListStores: React.FC = () => {
  const [stores, setStores] = useState([] as Store[]);
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  useEffect(() => {
    callApi.get<Store[]>('/store')
      .then(({ data }) => {
        setStores(data);
      })
  }, [callApi])

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Logitech Official Store</h1>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${url}/new`}>
            <Button color='green'>New store</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Table sortable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>URL</Table.HeaderCell>
            <Table.HeaderCell>Image</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {stores.map(store => (
            <Table.Row key={store.id}>
              <Table.Cell>{store.id}</Table.Cell>
              <Table.Cell>{store.name}</Table.Cell>
              <Table.Cell>{store.url}</Table.Cell>
              <Table.Cell><Image src={store.image} size="small" /></Table.Cell>
              <Table.Cell><ActionButtons actions={['edit', 'delete']} elementId={store.id} onDeleteClick={() => { showModal(DeleteStore, { id: store.id, name: store.name }) }} /></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}