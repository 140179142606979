import React from 'react';
import { Route } from 'react-router-dom'
import { EditProduct } from '../components/Main/Product/EditProduct';
import { ListProducts } from '../components/Main/Product/ListProducts';
import { NewProduct } from '../components/Main/Product/NewProduct';
import { ShowProduct } from '../components/Main/Product/ShowProduct';

export const ProductRoutes = () => {
  return (
    <>
      <Route path='/product' exact component={ListProducts} />
      <Route path='/product/new' component={NewProduct} />
      <Route path='/product/show/:id' component={ShowProduct} />
      <Route path='/product/edit/:id' component={EditProduct} />
    </>
  )
}