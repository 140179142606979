import React from "react";
import { Route } from "react-router-dom";
import { ListPromoCodes } from "../components/Main/PromoCode/ListPromoCodes";

export const PromoCodeRoutes = () => {
  return (
    <>
      <Route path="/Promocode" exact component={ListPromoCodes} />
    </>
  );
};

