import { Tab } from 'semantic-ui-react'
import React, { useState, useEffect, useRef } from "react";
import { EditReward } from './Reward';


const panes = [
  {
    menuItem: 'Logitech Rewards',
    render: () => <Tab.Pane attached={false} key={"setting" + 2}>
      <EditReward id={2} />
    </Tab.Pane>,
  },
  // {
  //   menuItem: 'Logitech Mx',
  //   render: () => <Tab.Pane attached={false}> <EditReward id={3} key={"setting" + 3} />
  //   </Tab.Pane>,
  // },
  ,
  {
    menuItem: 'Logitech ',
    render: () => <Tab.Pane attached={false}> <EditReward id={4} key={"setting" + 4} />
    </Tab.Pane>,
  }
]

export const LandingTab: React.FC = () => {
  return (
    <Tab menu={{ pointing: true }} panes={panes} />
  );
};
