import React, { useState, createContext, useContext, useEffect } from 'react';

type AuthTokens = {
  accessToken: string;
  refreshToken: string;
} | null;

type IAuthContextValue = {
  authTokens: AuthTokens;
  loginHandler: (accessToken: string, refreshToken: string) => void;
  logoutHandler: () => void;
  refreshSession: () => void;
}

const AuthContext = createContext({} as IAuthContextValue);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [authTokens, setAuthTokens] = useState<AuthTokens>(null);
  const [timer, setTimer] = useState<Timer>();

  function loginHandler(accessToken: string, refreshToken: string) {
    setAuthTokens({ accessToken, refreshToken });
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  function logoutHandler() {
    setAuthTokens(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  const refreshSession = () => {
    timer?.reset();
  }

  //Initialize session timeout
  useEffect(() => {
    setTimer(new Timer(logoutHandler, SESSION_IDLE_TIMEOUT));
  }, []);

  // Load the tokens from localStorage once
  useEffect(() => {
    const storedAccessToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');

    if (!!storedAccessToken && !!storedRefreshToken) {

      setAuthTokens({
        accessToken: storedAccessToken,
        refreshToken: storedRefreshToken
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authTokens, loginHandler, logoutHandler, refreshSession }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): IAuthContextValue => {
  return useContext(AuthContext);
}

class Timer {
  public handler: NodeJS.Timeout;

  constructor(private callback: () => void, private duration: number) {
    this.callback = callback;
    this.duration = duration;
    this.handler = setTimeout(callback, duration);
  }

  reset() {
    clearTimeout(this.handler);
    this.handler = setTimeout(this.callback, this.duration);
  }
}

const SESSION_IDLE_TIMEOUT = 60 * 60 * 1000;