import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Header,
  Divider,
  Form,
  Grid,
  InputOnChangeData,
  Message,
  DropdownProps,
  Segment,
  FormRadio,
  Accordion
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { AxiosResponseError } from "../../../types/axios";
import axios from "axios";
import { UploadImage } from "../../../types/fileTypes";
import { Images } from "../../Input/Images";
import {
  LandingPages,
  LandingPagesJson,
  LandingPagesAll,
  AllProduct,
  AllReseller,
} from "./Mx.type";
import _, { values } from "lodash";
import { useForm, SubmitHandler, Controller, useFieldArray, DeepMap, FieldError, useController } from "react-hook-form";
import ReactQuill from "react-quill";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import EmailListController from "../../Input/EmailListController";
import { RichEditorController } from "../../Input/RichEditorController";

export interface FormProps extends Omit<LandingPages, "detail"> {
  detail: {
    banner?: UploadImage;
    mobile_banner?: UploadImage;
  };
}

type FormErrors = {
  [key in keyof FormProps]?: string;
};

type ErrorsType = DeepMap<LandingPagesJson, FieldError> & {
  [index: string]: any;
}


const schema = yup.object({
  contact_list_id: yup.string().required('Contact list id is required'),
  approve_email_template: yup.string().required('Approve template is required'),
  rejected_email_template: yup.string().required('Reject template is required'),
  maxDailyContestSubmissions: yup.number().typeError('Max daily contest submissions must be a number').positive('Max daily contest submissions greater than zero').required('Max daily lucky draw submission is required'),
  Resellers: yup.array().required('Resellers is required')
    .test('NotEmptyArr', 'Resellers is required', function (v) {
      if (v.length === 0) {
        return false;
      }
      return true;
    }),
}).required();

export const EditMx: React.FC = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [landingpages, setLandingpages] = useState({} as LandingPagesJson);
  const formContext = useForm<LandingPagesJson>({
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, trigger, setValue, getValues, setError, watch, reset, formState: { errors }, control } = formContext;
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [error, setErrors] = useState<AxiosResponseError | null>(null);
  const [loading, setLoading] = useState(false);
  const [allReseller, setAllReseller] = useState<AllReseller[]>();
  const [allOfficialProductCategory, setAllOfficialProductCategory] = useState<AllReseller[]>();

  const { callApi } = useApi();
  useEffect(() => {
    callApi
      .get<LandingPagesAll>("landing/1")
      .then(({ data }) => {
        const item = {
          ...data.landing,
        };

        const { ...rest } = item;
        reset(rest)
        setAllOfficialProductCategory(data.officialCategory);
        setAllReseller(data.resellers);
        // setFormData(item);
      })
      .catch(e => {
        console.log("api err", e)
        // if (e.response.status !== 404) {
        //   console.log(e.message);
        //   setErrors(e);
        // }
      });
  }, [callApi]);

  useEffect(() => {
    formValidate()
  }, [Object.keys(errors).length])


  const formValidate = (): boolean => {
    if (loading === true) {
      return false;
    }
    let isValid = true;
    const formErrors: FormErrors = {};
    const parsedErrors = errors as ErrorsType

    Object.keys(errors).forEach(k => {
      const val = parsedErrors[k]
      formErrors[k] = val.message
    })

    window.scrollTo(0, 0)
    setFormErrors({ ...formErrors });
    return isValid;
  };

  const onSubmit = async () => {
    try {
      if (loading === true) {
        return;
      }
      setLoading(true);
      const data = getValues();
      const { ...formData } = data;
      const params = new FormData();
      //@ts-ignore
      params.append('Resellers', JSON.stringify(formData.Resellers))
      params.append('privacy', formData.privacy)
      params.append('approve_email_template', formData.approve_email_template)
      params.append('rejected_email_template', formData.rejected_email_template)
      params.append('contact_list_id', formData.contact_list_id)

      params.append('maxDailyContestSubmissions', formData.maxDailyContestSubmissions.toString())
      params.append('adminEmailTemplate', formData.adminEmailTemplate)
      if (formData.adminEmails) {
        params.append('adminEmails', JSON.stringify(formData.adminEmails))
      }

      params.append('landingId', '2')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        }
      }
      await callApi.patch(`/landing/1`, params, config);

      setLoading(false);
      history.go(0);
    } catch (e) {
      console.log(e)
      setLoading(false);
      setErrors(e);
    }
  };



  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(formErrors).length} negative>
          <p>{formErrors[Object.keys(formErrors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || "Some thing went wrong"}</p>
        </Message>
      </>
    );
  };


  return (
    <div>
      <h1>Mx Landing Page</h1>

      {renderError()}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Accordion
          fluid
          defaultActiveIndex={0}
          panels={[
            {
              key: "content",
              title: "Content",
              content: {
                content: (
                  <Segment>
                    <Controller
                      control={control}
                      name="Resellers"
                      render={({
                        field
                      }) => {
                        return (
                          <Form.Dropdown
                            width="16"
                            label="Stores & Resellers"
                            placeholder="Stores & Resellers"
                            key={allReseller?.length + "reseller"}
                            {...field}
                            value={field.value || []}
                            onChange={(e, { name, value }) => {
                              setValue(name, value)
                            }}
                            ref={undefined}
                            fluid
                            multiple
                            search
                            selection
                            options={allReseller || []}
                          />)
                      }}
                    />
                  </Segment>
                ),
              },
            },
            {
              key: "pp",
              title: "Privacy Policy",
              content: {
                content: (
                  <Segment>
                    <RichEditorController name={"privacy"} control={control} />
                  </Segment>
                ),
              },
            },
            {
              key: "emailTemplates",
              title: "Email Template Settings",
              content: {
                content: (
                  <Segment>
                    <Message info>
                      <p>These template ids refer to Send Grid's Templates</p>
                    </Message>

                    <Controller
                      control={control}
                      name="approve_email_template"
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Input
                          label="Approved Template Id"
                          width="16"
                          {...field}
                          ref={undefined}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="rejected_email_template"
                      // rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Form.Input
                            label="Rejected Template Id"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        );
                      }}
                    />
                    <div style={{ display: "none" }}>
                      <Controller
                        control={control}
                        name="contact_list_id"
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Form.Input
                            label="Contact List"
                            width="16"
                            {...field}
                            ref={undefined}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="adminEmailTemplate"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Form.Input
                          label="Notify Admin Template Id"
                          width="16"
                          {...field}
                          ref={undefined}
                        />
                      )}
                    />
                  </Segment>
                ),
              },
            },
            {
              key: "others",
              title: "Others",
              content: {
                content: (
                  <Grid columns={3}>
                    <Grid.Column>
                      <Segment>
                        <Controller
                          control={control}
                          name="maxDailyContestSubmissions"
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Form.Input
                              label="Max daily lucky draw submission"
                              width="16"
                              {...field}
                              ref={undefined}
                            />
                          )}
                        />
                        <EmailListController<LandingPagesJson> formContext={formContext} name="adminEmails" />
                      </Segment>
                    </Grid.Column>
                  </Grid>
                ),
              },
            },
          ]}
        />
        <Form.Button
          style={{ marginTop: "50px" }}
          color="vk"
          // onSubmit={handleSubmit(aaa)}
          type="submit"
          loading={loading}
        >
          Save
        </Form.Button>
      </Form>
    </div >
  );
};
