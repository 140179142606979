import React, { createContext, useContext } from "react";
import { useAuth } from "./AuthContext";
import axios, { AxiosInstance } from "axios";

// const MX_SERVER_URL = "http://localhost:3001/cms";
const MX_SERVER_URL =
  process.env.REACT_APP_MX_SERVER_URL ||
  "https://logitechmx-server-staging-2my5y.ondigitalocean.app/cms";
// const MX_SERVER_URL = "https://logitechmx-server-staging-2my5y.ondigitalocean.app/cms";

type AuthApiResponse = {
  accessToken: string;
  refreshToken: string;
};

type ApiContextValue = {
  callApi: AxiosInstance;
};

const ApiContext = createContext({} as ApiContextValue);

export const ApiContextProvider: React.FC = ({ children }) => {
  const { authTokens, loginHandler, logoutHandler, refreshSession } = useAuth();

  const callApi = axios.create({
    baseURL: MX_SERVER_URL,
    headers: {
      authorization: `Bearer ${authTokens?.accessToken}`,
    },
  });

  // Interceptor on request helps refresh the active session
  callApi.interceptors.request.use((config) => {
    refreshSession();

    return config;
  });

  // Interceptor helps handling API call fails centrally
  callApi.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      switch (error.response.status) {
        case 401: {
          await refreshAuthTokens();
          break;
        }
        case 440: {
          logoutHandler();
          break;
        }
      }

      return Promise.reject(error);
    }
  );

  const refreshAuthTokens = async () =>
    axios
      .post<AuthApiResponse>(
        "/admin/refreshToken",
        {
          refreshToken: `${authTokens?.refreshToken}`,
        },
        {
          baseURL: MX_SERVER_URL,
        }
      )
      .then(({ data }) => {
        loginHandler(data.accessToken, data.refreshToken);
      })
      .catch((e) => {
        logoutHandler();
      });

  return (
    <ApiContext.Provider value={{ callApi }}>{children}</ApiContext.Provider>
  );
};

export const useApi = (): ApiContextValue => {
  return useContext(ApiContext);
};
