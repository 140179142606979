import React from 'react';
import { ApiContextProvider } from './ApiContext';
import { AuthContextProvider } from './AuthContext';
import { ModalContextProvider } from './ModalContext';
import { UserContextProvider } from './UserContext';

const AppProviders: React.FC = ({ children }) => {
  return (
    <AuthContextProvider>
      <ApiContextProvider>
        <UserContextProvider>
          <ModalContextProvider>
            {children}
          </ModalContextProvider>
        </UserContextProvider>
      </ApiContextProvider>
    </AuthContextProvider>
  )
}

export default AppProviders;