import React from 'react';
import { Route } from 'react-router-dom'
import { EmailTemplate } from '../components/Main/EmailTemplate/EmailTemplate';

export const EmailTemplateRoutes = () => {
  return (
    <>
      <Route path='/template' exact component={EmailTemplate} />
    </>
  )
}