import { Tab } from 'semantic-ui-react'
import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { ContactList } from './ContactList';




export const TTab: React.FC<RouteComponentProps> = ({ ...rest }) => {

    const panes = [
        {
            menuItem: 'Logitech Rewards',
            render: () => <Tab.Pane attached={false}>
                <ContactList {...rest} landingId={2} key={"landing" + 2} />
            </Tab.Pane>,
        },
        // {
        //     menuItem: 'Logitech Mx',
        //     render: () => <Tab.Pane attached={false}>
        //         <ContactList {...rest} landingId={3} key={"landing" + 3} />
        //     </Tab.Pane>,
        // },
        {
            menuItem: 'Logitech ',
            render: () => <Tab.Pane attached={false}>
                <ContactList {...rest} landingId={4} key={"landing" + 4} />
            </Tab.Pane>,
        }
    ]
    return (
        <Tab menu={{ pointing: true }} panes={panes} />
    );
};
