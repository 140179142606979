import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, InputOnChangeData, Message, Segment } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { AxiosResponseError } from '../../../types/axios';
import { Images } from '../../Input/Images';
import { ProductCategory } from './product.type';

type FormProps = Omit<ProductCategory, 'id'>;

type FormErrors = {
  [key in keyof FormProps]?: string
}

export type HandleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;

export const NewProductCategory: React.FC = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [error, setError] = useState<AxiosResponseError>();
  const [errors, setErrors] = useState<FormErrors>({});
  const { callApi } = useApi();

  const handleChange: HandleInputChange
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    try {
      if (formValidate()) {
        await callApi.post('/productCategory', formData)
        history.push('/productCategory');
      }
    } catch (e) {
      setError(e);
    }
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors] || 'Some thing went wrong'}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  return (
    <div>
      <h1>Create New Product</h1>
      <Segment>
        {renderError()}
        <Form>
          <Form.Field>
            <Form.Input name='name' label="Name" width="8" onChange={handleChange}></Form.Input>
          </Form.Field>
          <Form.Button color='vk' onClick={onSubmit}>Submit</Form.Button>
        </Form>
      </Segment>
    </div>
  );
}