import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, Menu, Sidebar, Accordion, Divider } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";

type SideBarItemProps = {
  itemName: string;
  icon: SemanticICONS;
  navlink?: string;
  active?: boolean;
};

const SideBarItem: React.FC<SideBarItemProps> = ({
  itemName,
  icon,
  navlink,
  active = false,
}) => {
  const location = useLocation();
  if (!navlink) navlink = pluralize.singular(itemName).toLowerCase();

  return (
    <NavLink to={`/${navlink}`}>
      <Menu.Item active={active ? active : `/${navlink}` == location.pathname}>
        <Icon name={icon} />
        {itemName}
      </Menu.Item>
    </NavLink>
  );
};

const MXContent: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <div style={{ paddingLeft: "20px" }}>
        <SideBarItem
          icon="home"
          itemName="Setting"
          navlink="landingpages/mx/setting"
        />
        <SideBarItem
          active={location.pathname.includes("landingpages/mx/campaign")}
          icon="flag"
          itemName="Campaigns"
          navlink="landingpages/mx/campaign"
        />
        <SideBarItem
          icon="archive"
          itemName="Submissions"
          navlink="landingpages/mx/submission"
        />
        <SideBarItem
          icon="tty"
          itemName="Contact List"
          navlink="landingpages/mx/contactList"
        />
      </div>
    </>
  );
};
const RewardContent: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <div>
        <SideBarItem
          icon="home"
          itemName="Setting"
          navlink="landingpages/setting"
        />
        <SideBarItem
          icon="flag"
          active={location.pathname.includes("landingpages/campaign")}
          itemName="Campaigns"
          navlink="landingpages/campaign"
        />
        <SideBarItem
          icon="archive"
          itemName="Submissions"
          navlink="landingpages/submission"
        />
        <SideBarItem
          icon="tty"
          itemName="Contact List"
          navlink="landingpages/contactList"
        />
        <Divider />
        <SideBarItem
          icon="boxes"
          active={location.pathname.split("/")[1] == "officialProductCategory"}
          itemName="Official Product Category"
          navlink="officialProductCategory"
        />
        <SideBarItem
          icon="box"
          active={location.pathname.split("/")[1] == "officialProduct"}
          itemName="Official Product "
          navlink="officialProduct"
        />
        <SideBarItem
          icon="file image outline"
          active={location.pathname.includes("banners")}
          itemName="Banners"
          navlink="banners"
        />
      </div>
    </>
  );
};

const rootPanels = [
  { key: "mxLanding", title: "MX", content: { content: <MXContent /> } },
  // {
  //   key: "rewardLanding",
  //   title: "Reward",
  //   content: { content: <RewardContent /> },
  // },
];

const DropDownMenu: React.FC = () => {
  const location = useLocation();
  var activeIndex = undefined;
  if (location.pathname.includes("landingpages/mx/")) {
    activeIndex = 0;
  }
  // else if (location.pathname.includes("landingpages/reward/")) {
  //   activeIndex = 1;
  // }

  return (
    <Accordion inverted defaultActiveIndex={activeIndex} panels={rootPanels} />
  );
};

export const SideBar: React.FC = () => {
  const location = useLocation();
  return (
    <Sidebar as={Menu} inverted visible vertical animation="push">
      {/* <DropDownMenu /> */}
      <RewardContent />
      {/* <SideBarItem icon='user' itemName='Members' /> */}
      <SideBarItem
        icon="boxes"
        active={location.pathname.split("/")[1] == "productCategory"}
        itemName="Campaign Product Categories"
        navlink="productCategory"
      />
      <SideBarItem
        icon="box"
        itemName="Campaign Product"
        active={location.pathname.split("/")[1] == "product"}
        navlink="product"
      />
      {/* <SideBarItem icon='shopping bag' itemName='Logitech Official Stores' navlink='store' /> */}
      <SideBarItem
        icon="gift"
        active={location.pathname.split("/")[1] == "prize"}
        itemName="Prizes"
      />
      <SideBarItem
        icon="app store ios"
        active={location.pathname.split("/")[1] == "reseller"}
        itemName="Stores & Resellers"
        navlink="reseller"
      />
      <SideBarItem
        icon="gift"
        active={location.pathname.split("/")[1] == "promocode"}
        itemName="Promo Code"
        navlink="promocode"
      />
      {/* <SideBarItem icon='mail' itemName='EDM' /> */}
      {/* <SideBarItem icon='paper plane' itemName='Email template' navlink='template' /> */}
      {/* <SideBarItem icon='setting' active={location.pathname.split('/')[1] == 'Setting'} itemName='Setting' /> */}
    </Sidebar>
  );
};
