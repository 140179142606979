import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Button,
  Grid,
  Table,
  Image,
  Segment,
  Message,
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { useModal } from "../../../contexts/ModalContext";
import { ActionButtons } from "../../Button/ActionButtons";
import { DeletePrize } from "./DeleteOfficialProductCategory";
import { OfficialProductCategory } from "./OfficialProductCategory.type";

export const ListOfficialProductCategory: React.FC = () => {
  const [officialProductCategory, setOfficialProductCategory] = useState(
    [] as OfficialProductCategory[]
  );
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  useEffect(() => {
    callApi
      .get<OfficialProductCategory[]>("/officialProductCategory")
      .then(({ data }) => {
        setOfficialProductCategory(data);
      });
  }, [callApi]);

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Official Product Categories</h1>
          <Message info size="small">
            Product Category that will be displayed on Rewards Home Page, not on
            Submission
          </Message>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${url}/new`}>
            <Button color="green">New Official Product Category</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Segment>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {officialProductCategory.map((prize, key) => (
              <Table.Row key={prize.id}>
                <Table.Cell>{key + 1}</Table.Cell>
                <Table.Cell>{prize.name}</Table.Cell>
                <Table.Cell>{prize.description}</Table.Cell>
                <Table.Cell>
                  <ActionButtons
                    actions={["edit", "delete"]}
                    elementId={prize.id}
                    onDeleteClick={() => {
                      showModal(DeletePrize, {
                        id: prize.id,
                        name: prize.name,
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};
