import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownProps,
  Pagination,
  Search,
  SearchProps,
  Table,
  DropdownItemProps,
  Segment,
  Grid,
  Message,
  Header,
  List,
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import _ from "lodash";
import { Participant, ViewParticipant } from "./participant.type";
import "./ListParticipants.css";
import { csvLinkRef } from "../../Button/ExportCSV";
import { useModal } from "../../../contexts/ModalContext";
import { RejectParticipant } from "./RejectParticipant";
import { ParticipantView } from "./ViewParticipant";
import { CSVLink } from "react-csv";
import { ClearAllParticipants } from "./ClearAllParticipants";
import { ActionButtons } from "../../Button/ActionButtons";
import { ParticipantPopup } from "./ParticipantPopup";
import { RouteComponentProps } from "react-router-dom";
import { Campaign } from "../Campaign/campaign.type";

type Sortable = {
  column: string | null;
  direction: "ascending" | "descending" | undefined;
};

type DetailParticipantSelect = {
  [key: string]: boolean;
};

type PagProps = {
  numShow: number;
  currentPage: number;
};

type Filters = {
  status?: Participant["status"];
  suspicious?: "on" | "off";
  search: string | null;
};

type ParticipantApiReturn = {
  rows: Participant[];
  count: number;
};

type FilterType = {
  [x: string]: string | number | boolean | undefined;
};

type LandingType = {
  [key: string]: string;
};

type CampaignFilterType = {
  id: string;
  value: string;
};

const showDropdownOptions = [
  {
    key: 1,
    text: "10",
    value: 10,
  },
  {
    key: 2,
    text: "20",
    value: 20,
  },
  {
    key: 3,
    text: "50",
    value: 50,
  },
  {
    key: 4,
    text: "100",
    value: 100,
  },
];

const mapRawToViewData = (data: Participant[]): ViewParticipant[] => {
  return data.map((entry) => ({
    id: entry.id,
    email: entry.email,
    name: entry.firstName + " " + entry.lastName,
    firstName: entry.firstName,
    lastName: entry.lastName,
    productType: entry.Product?.ProductCategory.name,
    model: entry.Product?.name,
    serialNumber: entry.serialNumber,
    reseller: entry.Reseller?.name,
    receiptNumber: entry.receiptNumber,
    receipt: entry.receipt,
    status: entry.status,
    serialNumberSuspicious: entry.serialNumberSuspicious,
    receiptNumberSuspicious: entry.receiptNumberSuspicious,
    emailSuspicious: entry.emailSuspicious,
    campaignName: entry.campaignName,
    phoneNo: entry.phoneNo,
    dob: entry.dob,
    gender: entry.gender,
  }));
};

interface Props extends RouteComponentProps<{ id?: string }> {
  landingId: number
}


export const ListParticipants: React.FC<Props> = ({ match, landingId }) => {
  const { id } = match.params;

  const [totalCount, setTotalCount] = useState<number>(0);
  const [participants, setParticipants] = useState([] as ViewParticipant[]);
  const [detailSelects, setDetailSelects] = useState<DetailParticipantSelect>(
    {}
  );
  const [pagination, setPagination] = useState<PagProps>({
    numShow: showDropdownOptions[0].value,
    currentPage: 1,
  });
  const [sortable, setSortable] = useState<Sortable>({
    column: null,
    direction: undefined,
  });
  const [searching, setSearching] = useState<string | undefined>();
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState<
    number | undefined | null | string | boolean | object
  >();

  const [filter, setFilter] = useState<FilterType>();
  const [campaignFilter, setCampaignFilter] = useState<CampaignFilterType[]>();
  const landingType: LandingType = { mx: "1" };

  const { column: sortingColumn, direction } = sortable;
  const { numShow, currentPage } = pagination;

  // Hooks
  const { callApi } = useApi();
  const { showModal } = useModal();

  const changeFilter = async (
    columnName: string,
    value?: string | number | boolean
  ): Promise<void> => {
    setFilter({ ...filter, [columnName]: value });
  };

  const onStatusDropDownChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: DropdownItemProps
  ) => {
    changeFilter("status", data?.value);
  };

  const onSuspiciousDropDownChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: DropdownItemProps
  ) => {
    changeFilter("suspicious", data?.value);
  };

  const onCampaignDropDownChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: DropdownItemProps
  ) => {
    setCampaignName(data.text);
    changeFilter("campaignId", data?.value);
  };

  const changeSort = async (
    columnName: keyof ViewParticipant
  ): Promise<any> => {
    if (sortingColumn === columnName) {
      setSortable({
        ...sortable,
        direction: direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      setSortable({
        column: columnName,
        direction: "ascending",
      });
    }
  };

  const onSearching = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: SearchProps
  ) => {
    setSearching(data?.value);
  };

  const onNumShowChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPagination({ currentPage: 1, numShow: data?.value as number });
  };

  const approveParticipant = (participantId: number, reason: string) => {
    const participant = participants.find((p) => p.id === participantId);
    if (participant) {
      participant.status = "approved";
      setParticipants([...participants]);
    }
    renderParticipant(
      currentPage,
      numShow,
      sortable.column as string,
      direction === "ascending" ? "DESC" : "ASC",
      filter,
      searching
    );
    return;
  };
  const rejectParticipant = async (participantId: number, reason: string) => {
    await callApi.post("/participant/reject", { participantId, reason });

    const participant = participants.find((p) => p.id === participantId);

    if (participant) {
      participant.status = "rejected";
      setParticipants([...participants]);
    }
    renderParticipant(
      currentPage,
      numShow,
      sortable.column as string,
      direction === "ascending" ? "DESC" : "ASC",
      filter,
      searching
    );
  };

  const renderActionButtons = (
    detailSelecting: boolean,
    participant: ViewParticipant
  ) => {
    if (participant.status !== "pending") {
      return (
        <Dropdown icon="ellipsis vertical" pointing>
          <Dropdown.Menu>
            <Dropdown.Item
              text={detailSelecting ? "Show less" : "Show more"}
              icon="info"
              onClick={() => {
                if (detailSelecting) {
                  setDetailSelects({
                    ...detailSelects,
                    [participant.id]: false,
                  });
                } else {
                  setDetailSelects({
                    ...detailSelects,
                    [participant.id]: true,
                  });
                }
              }}
            />
            {participant.status === "rejected" && (
              <Dropdown.Item
                text="Delete"
                icon="trash"
                onClick={() =>
                  showModal(ParticipantPopup, {
                    id: participant.id,
                    type: "delete",
                    onSet: () => { },
                  })
                }
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown icon="ellipsis vertical" pointing>
          <Dropdown.Menu>
            <Dropdown.Item
              text={detailSelecting ? "Show less" : "Show more"}
              icon="info"
              onClick={() => {
                if (detailSelecting) {
                  setDetailSelects({
                    ...detailSelects,
                    [participant.id]: false,
                  });
                } else {
                  setDetailSelects({
                    ...detailSelects,
                    [participant.id]: true,
                  });
                }
              }}
            />
            <Dropdown.Item
              text="Approve"
              icon="check"
              onClick={() =>
                showModal(ParticipantPopup, {
                  id: participant.id,
                  type: "approve",
                  onSet: approveParticipant,
                })
              }
            />
            <Dropdown.Item
              text="Reject"
              icon="close"
              onClick={() =>
                showModal(RejectParticipant, {
                  participantId: participant.id,
                  onReject: rejectParticipant,
                })
              }
            />
            <Dropdown.Item
              text="Delete"
              icon="trash"
              onClick={() =>
                showModal(ParticipantPopup, {
                  id: participant.id,
                  type: "delete",
                  onSet: () => { },
                })
              }
            />
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  const renderParticipant = async (
    page: number,
    limit: number,
    sort_column?: string,
    order?: string,
    filter?: Object,
    wildCard?: string
  ) => {
    callApi
      .get<ParticipantApiReturn>("/participant", {
        params: {
          limit,
          page,
          sort_column,
          order,
          filter,
          landingId: landingType[id] ? landingType[id] : landingId,
          wildCard,
        },
      })
      .then(({ data }) => {
        const viewData = mapRawToViewData(data.rows);

        const initSelects = _.keyBy(data, "id") as any;
        for (const key in initSelects) {
          initSelects[key] = false;
        }
        setPagination({ currentPage: page, numShow: limit });
        setTotalCount(data.count);
        setParticipants(viewData);
        setDetailSelects(initSelects);
      });
  };

  const renderCampaign = async () => {
    callApi
      .get<Campaign[]>("/campaign", { params: { landingId: landingType[id] ? landingType[id] : landingId } })
      .then(({ data }) => {
        const campaigns = data.map((x) => {
          return { id: x.id + "", value: x.name };
        });
        setCampaignFilter(campaigns);
        setCampaignName(campaigns.length > 0 ? campaigns[0].value : "");
        changeFilter("campaignId", campaigns.length > 0 ? campaigns[0].id : 0);
      });
  };

  useEffect(() => {
    if (filter || searching || direction) {
      renderParticipant(
        1,
        numShow,
        sortable.column as string,
        direction === "ascending" ? "DESC" : "ASC",
        filter,
        searching
      );
    }
  }, [callApi, filter, searching, direction, numShow]);

  const exportCsv = async () => {
    setLoading(true);
    const {
      data: { rows },
    } = await callApi.get("/participant/all", {
      params: {
        landingId: landingType[id] ? landingType[id] : landingId,
        campaignId: filter?.campaignId,
      },
    });

    const dt = rows.map((entry) => {
      entry.campaignName = campaignName as string;
      entry.productType = entry.Product?.ProductCategory.name;
      entry.model = entry.Product?.name;
      entry.reseller = entry.Reseller?.name;
      const obj = Object.assign({}, entry);
      return obj;
    });

    setExportData(dt);
    csvLinkRef.current?.link?.click();
    setLoading(false);
  };

  useEffect(() => {
    renderCampaign();
  }, [id, landingId]);

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Submissions</h1>
          <Message info size="small">
            <Header>Suspicious Rules</Header>
            Either of the following property is in multiple entry of the
            campaign's submission list :
            <List bulleted>
              <List.Item>Email</List.Item>
              <List.Item>Receipt No.</List.Item>
              <List.Item>Product S/N</List.Item>
            </List>
          </Message>
        </Grid.Column>
      </Grid>
      <Segment>
        <Search
          className="search-bar"
          onSearchChange={_.debounce(onSearching, 500)}
          showNoResults={false}
          size="mini"
        />

        <Dropdown
          text="Suspicious"
          icon="filter"
          labeled
          button
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text="Off"
              active={filter?.suspicious === "off" || !filter?.suspicious}
              value="off"
              onClick={onSuspiciousDropDownChange}
            />
            <Dropdown.Item
              text="On"
              value="on"
              active={filter?.suspicious === "on"}
              onClick={onSuspiciousDropDownChange}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown text="Campaign" icon="filter" labeled button className="icon">
          <Dropdown.Menu>
            {campaignFilter?.map((value, key) => {
              const { id, value: val } = value;
              return (
                <Dropdown.Item
                  text={val || ""}
                  active={id == filter?.campaignId}
                  value={id}
                  key={id}
                  onClick={onCampaignDropDownChange}
                />
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Status" icon="filter" labeled button className="icon">
          <Dropdown.Menu>
            <Dropdown.Item
              text="All"
              value={undefined}
              active={!filter?.status}
              onClick={onStatusDropDownChange}
            />
            <Dropdown.Item
              text="Pending"
              value="pending"
              active={filter?.status === "pending"}
              onClick={onStatusDropDownChange}
            />
            <Dropdown.Item
              text="Approved"
              value="approved"
              active={filter?.status === "approved"}
              onClick={onStatusDropDownChange}
            />
            <Dropdown.Item
              text="Rejected"
              value="rejected"
              active={filter?.status === "rejected"}
              onClick={onStatusDropDownChange}
            />
          </Dropdown.Menu>
        </Dropdown>

        <div className="table-header-right">
          <Dropdown text="Action" button loading={loading}>
            <Dropdown.Menu>
              <Dropdown.Item text="Export" onClick={exportCsv} />
              <Dropdown.Item
                text="Clear entries"
                onClick={() =>
                  showModal(ClearAllParticipants, {
                    campaignId: filter?.campaignId,
                    landingId: landingType[id] ? landingType[id] : landingId + "",
                  })
                }
              />
              <CSVLink
                hidden
                data={exportData}
                headers={
                  participants[0] &&
                  Object.keys(participants[0]).filter(
                    (key) =>
                      ![
                        "serialNumberSuspicious",
                        "receiptNumberSuspicious",
                        "emailSuspicious",
                      ].includes(key)
                  )
                }
                filename={`Participants_${campaignName}_${Date.now()}.csv`}
                ref={csvLinkRef}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            text={`Show: ${numShow}`}
            button
            selection
            compact
            options={showDropdownOptions}
            defaultValue={showDropdownOptions[0].value}
            onChange={onNumShowChange}
          />

          <Pagination
            disabled={participants.length == 0}
            className="pagination-bar"
            totalPages={Math.ceil(totalCount / numShow)}
            siblingRange={1}
            boundaryRange={0}
            ellipsisItem={null}
            onPageChange={(_e, data) =>
              renderParticipant(
                data.activePage as number,
                numShow,
                sortable.column as string,
                direction === "ascending" ? "DESC" : "ASC",
                filter,
                searching
              )
            }
            activePage={currentPage}
          />
        </div>
        <h3>{campaignName}</h3>
        <Table sortable striped columns={5}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => changeSort("email")}
                sorted={sortingColumn === "email" ? direction : undefined}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => changeSort("name")}
                sorted={sortingColumn === "name" ? direction : undefined}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => changeSort("productType")}
                sorted={sortingColumn === "productType" ? direction : undefined}
              >
                Product type
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => changeSort("model")}
                sorted={sortingColumn === "model" ? direction : undefined}
              >
                Model
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => changeSort("status")}
                sorted={sortingColumn === "status" ? direction : undefined}
              >
                Status
              </Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {participants.length == 0 ? (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell colSpan="7" textAlign="center">
                    No Record
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ) : (
              ""
            )}
            {participants.map((participant, key) => {
              const detailSelecting = detailSelects[participant.id];

              return (
                <React.Fragment key={participant.id}>
                  <Table.Row
                    negative={
                      (participant.emailSuspicious ||
                        participant.receiptNumberSuspicious ||
                        participant.serialNumberSuspicious) &&
                      participant.status == "pending"
                    }
                  >
                    <Table.Cell>
                      {" "}
                      #{key + 1 + (currentPage - 1) * numShow}
                    </Table.Cell>
                    <Table.Cell> {participant.email}</Table.Cell>
                    <Table.Cell width={2}>{participant.name}</Table.Cell>
                    <Table.Cell width={2}>{participant.productType}</Table.Cell>
                    <Table.Cell width={2}>{participant.model}</Table.Cell>
                    <Table.Cell
                      width={2}
                      negative={participant.status === "rejected"}
                      positive={participant.status === "approved"}
                    >
                      {participant.status}
                    </Table.Cell>
                    <Table.Cell>
                      {renderActionButtons(detailSelecting, participant)}
                    </Table.Cell>
                  </Table.Row>
                  {detailSelecting ? (
                    <Table.Row>
                      <Table.Cell colSpan={6}>
                        <ParticipantView data={participant} />
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};
