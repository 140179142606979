import React from 'react';
import { Route } from 'react-router-dom'
import { ListBanners } from '../components/Main/Banners/BannerList';
import { BannersForm } from '../components/Main/Banners/BannerForm';

export const BannersRoutes = () => {
    return (
        <>
            <Route path='/banners' exact component={ListBanners} />
            <Route path='/banners/new' exact component={BannersForm} />
            <Route path='/banners/edit/:id' exact component={BannersForm} />
        </>
    )
}