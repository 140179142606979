import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Button,
  Grid,
  Table,
  Image,
  Segment,
  Message,
} from "semantic-ui-react";
import { useApi } from "../../../contexts/ApiContext";
import { useModal } from "../../../contexts/ModalContext";
import { ActionButtons } from "../../Button/ActionButtons";
import { DeleteBanners } from "./DeleteBanners";
import { Banners } from "./Banner.type";

export const ListBanners: React.FC = () => {
  const [banners, setBanners] = useState([] as Banners[]);
  const { callApi } = useApi();
  const { showModal } = useModal();
  const { url } = useRouteMatch();

  useEffect(() => {
    callApi.get<Banners[]>("/banners").then(({ data }) => {
      setBanners(data);
    });
  }, [callApi]);

  return (
    <>
      <Grid columns={16}>
        <Grid.Column width={13}>
          <h1>Banners</h1>
          <Message info size="small">
            Banners that will be displayed on Rewards Home Page.
          </Message>
        </Grid.Column>
        <Grid.Column width={3}>
          <Link to={`${url}/new`}>
            <Button color="green">New Banner</Button>
          </Link>
        </Grid.Column>
      </Grid>
      <Segment>
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {banners.map((banner, key) => (
              <Table.Row key={banner.id}>
                <Table.Cell>{"#" + (key + 1)}</Table.Cell>
                <Table.Cell> {banner.name}</Table.Cell>
                <Table.Cell>
                  <Image src={banner.desktopImage} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <ActionButtons
                    actions={["edit", "delete"]}
                    elementId={banner.id}
                    onDeleteClick={() => {
                      showModal(DeleteBanners, {
                        id: banner.id,
                        name: banner.title,
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};
