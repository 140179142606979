import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import './NavBar.css';

export const NavBar: React.FC = () => {
  const { authTokens, logoutHandler } = useAuth();
  const { user } = useUser();

  return (
    <Menu inverted={!!authTokens} className='navbar'>
      <Menu.Item
      >
        <p style={{ fontSize: '120%' }}>Logitech</p>
      </Menu.Item>
      <Menu.Menu
        position="right"
      >
        <Dropdown
          icon='user'
          text={user?.name}
          floating
          item
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}