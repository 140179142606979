import React from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { Button, SemanticCOLORS } from "semantic-ui-react";
import { removeDuplicate } from "../../utils";

type Actions = Array<"view" | "edit" | "delete" | "action">;

const ActionIconProps: {
  [key in Actions[0]]: { name: string; color: SemanticCOLORS };
} = {
  view: {
    name: "eye",
    color: "blue",
  },
  edit: {
    name: "edit",
    color: "teal",
  },
  delete: {
    name: "trash",
    color: "red",
  },
  action: {
    name: "object ungroup outline",
    color: "grey",
  },
};

type Props = {
  elementId?: number;
  actions: Actions;
  onDeleteClick?: () => void;
  onActionClick?: () => void;
};

export const ActionButtons: React.FC<Props> = ({
  actions,
  elementId,
  onDeleteClick,
  onActionClick
}) => {
  const { url } = useRouteMatch();
  actions = removeDuplicate(actions); // Remove duplicate action elements
  const history = useHistory();

  return (
    <div>
      <Button.Group>
        {actions
          .filter(a => !['delete', 'action'].includes(a))
          .map(action => {
            const { name: iconName, color: iconColor } =
              ActionIconProps[action];

            return (
              <Button
                key={action}
                icon={iconName}
                color={iconColor}
                onClick={() => {
                  history.push(`${url}/${action}/${elementId}`);
                }}
                type="button"
              ></Button>
            );
          })}
        {actions.includes("delete") ? (
          <Button
            key={ActionIconProps["delete"].name}
            icon={ActionIconProps["delete"].name}
            color={ActionIconProps["delete"].color}
            onClick={onDeleteClick}
          />
        ) : null}
        {actions.includes("action") ? (
          <Button
            key={ActionIconProps["action"].name}
            icon={ActionIconProps["action"].name}
            color={ActionIconProps["action"].color}
            onClick={onActionClick}
          />
        ) : null}
      </Button.Group>
    </div>
  );
};
