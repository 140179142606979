import React from 'react';
import { Route } from 'react-router-dom'
import { Homepage } from '../components/Main/Homepage/Homepage';

export const HomepageRoutes = () => {
  return (
    <>
      <Route path='/homepage' exact component={Homepage} />
    </>
  )
}