import React from 'react';
import { Route } from 'react-router-dom'
import { EditMxCampaign } from '../components/Main/Campaign/EditMxCampaign';
import { ListCampaign } from '../components/Main/Campaign/ListCampaign';
import { TTab } from '../components/Main/Campaign/Tab';
import { RewardForm } from '../components/Main/Campaign/RewardForm';

export const CampaignRoutes = () => {
  return (
    <>
      {/* <Route path='/campaign' exact component={CampaignView} /> */}
      {/* <Route path='/landingpages/:id/campaign' exact component={ListCampaign} />
      <Route path='/landingpages/mx/campaign/edit/:id' exact component={EditMxCampaign} />
      <Route path='/landingpages/mx/campaign/new' component={EditMxCampaign} /> */}

      <Route path='/landingpages/campaign' exact component={TTab} />

      <Route path='/landingpages/mix/:landingId/campaign/new' component={RewardForm} />
      <Route path='/landingpages/campaign/edit/:id' component={RewardForm} />
    </>
  )
}