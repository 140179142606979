import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { Store } from './store.type';
import { UploadImage } from '../../../types/fileTypes';
import { HandleInputChange } from '../../../types/inputs';
import { Images } from '../../Input/Images';
import axios from 'axios';
import { AxiosResponseError } from '../../../types/axios';

interface FormProps extends Omit<Store, 'id' | 'image'> {
  image: UploadImage | null;
}

type FormErrors = {
  [key in keyof FormProps]?: string
}

export const NewStore: React.FC = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<FormProps>({} as FormProps);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<AxiosResponseError>();
  const [loading, setLoading] = useState(false);
  const { callApi } = useApi();

  const handleChange: HandleInputChange
    = (e, { name, value }) => setFormData({ ...formData, [name]: value } as Pick<FormProps, keyof FormProps>)

  const handleFileChange = (name: string, file: UploadImage) => {
    setFormData({ ...formData, [name]: file });
  }

  const handleFileRemove = (name: string) => {
    setFormData({ ...formData, [name]: null });
  }

  const formValidate = (): boolean => {
    let isValid = true;
    const errors: FormErrors = {};

    if (!formData.name) {
      errors.name = "Name is required"
      isValid = false;
    }

    if (!formData.image) {
      errors.image = "Image is required";
      isValid = false;
    }

    if (!formData.url) {
      errors.url = "URL is required";
      isValid = false;
    }

    setErrors({ ...errors });

    return isValid;
  }

  const onSubmit = async () => {
    if (formValidate()) {
      try {
        setLoading(true);
        let imageUrl = '';

        if (formData.image) {
          const { data: signedUrl } = await callApi.get<string>(`/s3/uploadUrl?folder=store&fileExtension=${formData.image.type.split('/')[1]}`);
          await axios.put(signedUrl, formData.image.data, {
            headers: {
              'Content-Type': formData.image.type
            }
          });

          imageUrl = signedUrl.split('?')[0];
        }

        await callApi.post('/store', { ...formData, image: imageUrl });
        history.push('/store');
      }
      catch (e) {
        setLoading(false);
        setError(e);
      }
    }
  }

  const renderError = () => {
    return (
      <>
        <Message hidden={!Object.keys(errors).length} negative>
          <p>{errors[Object.keys(errors)[0] as keyof FormErrors]}</p>
        </Message>
        <Message hidden={!error} negative>
          <p>{error?.response?.data || 'Some thing went wrong'}</p>
        </Message>
      </>
    )
  }

  return (
    <div>
      <h1>Create New Store</h1>
      {renderError()}
      <Form>
        <Form.Field>
          <Form.Input name='name' label="Name" width="8" onChange={handleChange}></Form.Input>
          <Form.Input name='url' label="URL" width="8" onChange={handleChange}></Form.Input>
          <label>Image</label>
          <Images name='image' onSuccess={handleFileChange} onRemove={handleFileRemove} />
        </Form.Field>
        <Form.Button color='vk' onClick={onSubmit} loading={loading}>Submit</Form.Button>
      </Form>
    </div>
  );
}