import React from 'react';
import { Route } from 'react-router-dom'
import { ListSubscribers } from '../components/Main/Subscriber/ListSubscribers';

export const SubscriberRoutes = () => {
  return (
    <>
      <Route path='/edm' exact component={ListSubscribers} />
    </>
  )
}