import React, { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useApi } from '../../../contexts/ApiContext';
import { Campaign } from '../Campaign/campaign.type';

export const Homepage: React.FC = () => {
  const [campaign, setCampaign] = useState<Campaign>();
  const [show, setShow] = useState(true);
  const { callApi } = useApi();

  useEffect(() => {
    callApi.get<Campaign>('/campaign/current')
      .then(({ data: campaignData }) => {
        setCampaign(campaignData);
        setShow(campaignData.show);
      })
      .catch(e => {
        if (e.response.status !== 404) {
          console.log(e.message);
        }
      })
  }, [callApi]);

  const onToggle = () => {
    callApi.post('/campaign/show', {
      campaignId: campaign?.id,
      show: !show
    }).then(() => {
      setShow(!show);
    })
  }

  return (
    <Checkbox
      disabled={!campaign}
      onChange={onToggle}
      label='Show campaign'
      toggle
      checked={show}
    />
  );
}