import React from 'react';
import { useApi } from '../../../contexts/ApiContext';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { Banners } from './Banner.type';

type Props = {
    id: number;
    name: Banners['title'];
    onRequestClose: () => void;
}

export const DeleteBanners: React.FC<Props> = ({ id, name, onRequestClose }) => {
    const { callApi } = useApi();
    const history = useHistory();

    return (
        <Modal open onClose={onRequestClose} size="mini" >
            <Modal.Header>Delete banners?</Modal.Header>
            <Modal.Content>{`Are you sure to delete ${name}`}</Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => callApi.delete(`/banners/${id}`).then(() => {
                        onRequestClose();
                        history.go(0);
                    })}>Delete</Button>
                <Button onClick={onRequestClose}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    );
}