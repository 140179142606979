import React, { useEffect, useState } from 'react';
import { useApi } from '../../../contexts/ApiContext';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

type Props = {
  id: number;
  type: string;
  onRequestClose: () => void;
  onSet: (participantId: number, reason: string) => void;
}

export const ParticipantPopup: React.FC<Props> = ({ type, id, onRequestClose, onSet }) => {
  const { callApi } = useApi();
  const history = useHistory();
  const [btnText, setBtnText] = useState<string>();

  const onclickFunction = () => {
    switch (type) {
      case 'delete':
        callApi.delete(`/participant/${id}`).then(() => {
          onRequestClose();
          history.go(0);
        })
        break;
      case 'approve':
        callApi.post("/participant/approve", { participantId: id }).then(() => {
          onSet(id, '');
          onRequestClose();
        })
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    switch (type) {
      case 'approve':
        setBtnText('Appove')
        break;
      default:
        setBtnText('Delete')
        break;
    }
  }, [type]);

  return (
    <Modal open onClose={onRequestClose} size="mini" >
      <Modal.Header>{btnText} participant</Modal.Header>
      <Modal.Content>{`Are you sure to ${btnText?.toLocaleLowerCase()} this participant`}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onclickFunction}>{btnText}</Button>
        <Button onClick={onRequestClose}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
}